import React, { useState } from "react";
import Footprint_Home_Card from "../Card/Footprint_Home_Card";
const Footprint_Home = () => {
  const [topSection, setTopSection] = useState(false);

  const handleTopSection = () => {
    setTopSection(true);
  };
  return (
    <>
      <div className="header-section">
        <div className="info">
          {/* {!topSection && (
            <div className="guide-info text-color-gray-light">
              <h2 className=" font-bold text-[24px] mt-2">Foot Prints</h2>
            </div>
          )} */}

          <div className="setup-guide mt-5">
            <div className="inner-setup-guide text-color-gray-light">
              <Footprint_Home_Card showSection={handleTopSection} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footprint_Home;
