import React, { useState, useEffect } from "react";
import Card from "../Card/Card";
const Guide1 = () => {
  const [topSection, setTopSection] = useState(false);

  const handleTopSection = () => {
    setTopSection(true);
  };

  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  useEffect(() => {
    // Listener for messages from the extension
    const messageListener = (event) => {
      if (event.source !== window) return;
      if (event.data.type === "EXTENSION_INSTALLED") {
        console.log("Extension is installed:", event.data.data);
        setIsExtensionInstalled(true);
      }
    };

    // Add event listener for messages
    window.addEventListener("message", messageListener);

    // Send a message to check if the extension is installed
    window.postMessage({ type: "CHECK_EXTENSION" }, "*");

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);
  return (
    <>
      <div className="header-section">
        <div className="info ">
          {/* {
                        !topSection &&
                        <div className="guide-info text-color-gray-light">
                            <h2 className=' font-bold text-[24px]'>Get ready to setup your website and add your footprints to it</h2>
                            <p className='text-xs'>We’ll hand hold you through the process of adding your website and then setting up your footprints.</p>
                        </div>
                    } */}

          <div className="setup-guide mt-5">
            <div className="inner-setup-guide text-color-gray-light">
              <Card showSection={handleTopSection} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Guide1;
