import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../config";
const Sidebar = () => {
  const navigate = useNavigate();
  const [website, setWebsite] = useState();
  const [activeIndex, setActiveIndex] = useState(null);

  console.log("ActiveIndex", activeIndex);

  const userId = localStorage.getItem("userId");
  const handleLogout = () => {
    localStorage.clear();
    navigate("/signIn");
  };

  const fetchWebsiteByUserId = async () => {
    try {
      const response = await axios.get(`${baseUrl}/websites/user/${userId}`);
      console.log(
        "fetch user website from sidebarrrrrr",
        response.data.websites.at(-1)
      );
      setWebsite(response.data.websites.at(-1));
    } catch (error) {
      console.log("Error in fetching data from sidebar", error.response);
    }
  };
  const trimUrl = (url) => {
    return url.length > 19 ? `${url.substring(0, 17)}..` : url;
  };

  useEffect(() => {
    if (userId) {
      fetchWebsiteByUserId();
    }
  }, [userId]);

  return (
    <div className="wrapper bg-light-side-bg">
      <div className="flex flex-col h-screen w-64   text-white">
        <nav className="flex-1 p-4">
          <ul className="space-y-2 text-color-gray-light">
            <li>
              <a
                className={`header-section p-2 space-x-4 text-color-gray-light flex items-center rounded-lg ${
                  activeIndex === 0
                    ? "bg-button-bg text-white"
                    : "hover:bg-[#F1F1F1] hover:text-black"
                }`}
              >
                <i class="fa-solid fa-globe"></i>

                <Link to={`/${website?._id}/footprint-all-list`}>
                  <span
                    onClick={() => setActiveIndex(0)}
                    className="cursor-pointer"
                  >
                    {website?.url ? trimUrl(website.url) : ""}
                  </span>
                </Link>
              </a>
            </li>

            <li>
              <a
                className={`flex items-center p-2 rounded-lg ${
                  activeIndex === 1
                    ? "bg-button-bg text-white"
                    : "hover:bg-[#F1F1F1] hover:text-black"
                }`}
              >
                <i class="fa-solid fa-gear"></i>
                <Link to={"/setting"}>
                  <span
                    className="ml-4 cursor-pointer"
                    onClick={() => setActiveIndex(1)}
                  >
                    Setting
                  </span>
                </Link>
              </a>
            </li>

            <li>
              <a className="flex items-center p-2 rounded-lg hover:bg-[#F1F1F1] hover:text-black cursor-pointer">
                <i className=" fa-solid fa-right-from-bracket"></i>
                <span className="ml-4" onClick={handleLogout}>
                  Logout
                </span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
