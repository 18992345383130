// import React, { useState } from "react";
// import Account from "./Account";
// import Sidebar from "./Sidebar";
// import Notification from "./Notification";

// const Setting = () => {
//   const [accountActive, setAccountActive] = useState(true);
//   const [notificationActive, setNotificationActive] = useState(false);
//   return (
//     // <div className="">
//     //   <Sidebar />
//     //   <Account />
//     // </div>
//     <>
//       <div
//         className="flex justify-center   mx-auto  bg-[#ffffff] rounded-lg"
//         style={{ width: "712px" }}
//       >
//         {/* <div class="flex justify-center flex-col space-y-5 rounded-lg border py-10 px-5  mx-auto"> */}
//         <Sidebar
//           setAccountActive={setAccountActive}
//           setNotificationActive={setNotificationActive}
//         />
//         {accountActive && <Account />}

//         {notificationActive && <Notification />}
//         {/* <Account /> */}
//       </div>
//     </>
//   );
// };

// export default Setting;

import React, { useState } from "react";
import Account from "./Account";
import Sidebar from "./Sidebar";
import Notification from "./Notification";

const Setting = () => {
  const [accountActive, setAccountActive] = useState(true);
  const [notificationActive, setNotificationActive] = useState(false);
  const activeItem = accountActive ? "account" : "notification";

  return (
    // <div className="">
    //   <Sidebar />
    //   <Account />
    // </div>
    <>
      <div
        className="flex justify-center  items-center  mx-auto  bg-[#ffffff] rounded-lg"
        style={{ width: "712px" }}
      >
        {/* <div class="flex justify-center flex-col space-y-5 rounded-lg border py-10 px-5  mx-auto"> */}
        <Sidebar
          setAccountActive={setAccountActive}
          setNotificationActive={setNotificationActive}
          activeItem={activeItem}
        />
        {accountActive && <Account />}

        {notificationActive && <Notification />}
        {/* <Account /> */}
      </div>
    </>
  );
};

export default Setting;
