import React, { useState } from "react";
import Footprint_Schedule_Card from "../Card/Footprint_Schedule_Card";
const Footprint_Schedule = ({
  footPrint,
  websiteId,
  isReadOnly,
  formData,
  setFormData,
}) => {
  const [topSection, setTopSection] = useState(false);

  const handleTopSection = () => {
    setTopSection(true);
  };
  return (
    <>
      <div className="header-section">
        <div className="info">
          {/* {!topSection && (
            <div className="guide-info text-color-gray-light">
              <h2 className=" font-bold text-[24px] mt-2">Foot Prints</h2>
            </div>
          )} */}

          <div className="setup-guide mt-5">
            <div className="inner-setup-guide text-color-gray-light">
              <Footprint_Schedule_Card
                footPrint={footPrint}
                websiteId={websiteId}
                isReadOnly={true}
                isEditable={true}
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footprint_Schedule;
