import axios from "axios";
import React, { useEffect, useState } from "react";

const FloatingInput = ({
  formData,
  setFormData,
  footPrint,
  isReadOnly,
  isEditable,
}) => {
  const [footprintNameError, setFootprintNameError] = useState("");
  const [tagsArray, setTagsArray] = useState([]);

  console.log("formData for edit", formData.tags, tagsArray);

  // let Tags = footPrint?.tags;
  // let footprintTags = Tags?.join(", ");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleKeyDown = (event) => {
    const { value } = event.target;

    if (event.key === "Enter" && value.trim() !== "") {
      event.preventDefault();
      // Add tag if "Enter" or "Space" is pressed and value is not empty
      setTagsArray([...tagsArray, value.trim()]);

      // Add tag to the tagsArray in formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        tagsArray: [...prevFormData.tagsArray, value.trim()], // Add new tag
        tags: "", // Clear the input field
      }));
    }
  };

  const removeTag = (index) => {
    const updatedTags = tagsArray.filter((_, i) => i !== index);
    setTagsArray(updatedTags);

    setFormData((prevFormData) => {
      const updatedTags = prevFormData.tagsArray.filter((_, i) => i !== index);
      return {
        ...prevFormData,
        tagsArray: updatedTags,
      };
    });
  };

  return (
    <div className="space-y-4 mx-4 py-2 flex flex-col gap-2">
      <div className="relative w-full text-[16px]">
        <input
          type="text"
          id="floatingInput1"
          name="footprintName"
          value={formData.footprintName}
          placeholder=" " // Keep the placeholder to trigger the peer classes
          disabled={!isEditable}
          onChange={handleChange}
          className="block w-full px-2.5 pb-2.5 pt-4 rounded-lg border text-xs bg-white border-[#A1A1A1] focus:outline-none peer"
        />
        <label
          htmlFor="floatingInput1"
          className="absolute text-color-gray-light duration-300 text-xs transform -translate-y-4  scale-75 top-2 z-10 origin-[0] bg-white px-1  left-2"
        >
          Test Name
        </label>
      </div>
      {/* Tags Input */}
      {/* <div className="relative w-full text-xs">
        <input
          type="text"
          id="floatingInput2"
          name="tags"
          value={formData.tags}
          disabled={!isEditable}
          onChange={handleChange}
          placeholder=" "
          className="block w-full px-2.5 pb-2.5 pt-4 rounded-lg border border-[#A1A1A1] focus:outline-none  peer"
        />
        <label
          htmlFor="floatingInput2"
          className="absolute text-gray-700 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#616161] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5"
        >
          Tags
        </label>
      </div> */}

      <div className="relative w-full text-xs">
        <div className="flex items-center flex-wrap gap-2 px-2 pb-2.5 pt-2 rounded-lg border border-[#A1A1A1] focus-within:outline-none">
          {formData?.tagsArray.map((tag, index) => (
            <div
              key={index}
              className="bg-gray-200 rounded-[4px] px-2 py-[2px] text-sm flex items-center"
            >
              <span>{tag}</span>
              {isEditable && (
                <button
                  type="button"
                  onClick={() => removeTag(index)}
                  className="ml-2 text-red-500 focus:outline-none"
                >
                  &times;
                </button>
              )}
            </div>
          ))}

          {isEditable && (
            <input
              type="text"
              id="floatingInput2"
              name="tags"
              value={formData.tags}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder=" "
              className="flex-grow block w-auto min-w-[10px] px-2.5 py-1 border-none focus:ring-0 focus:outline-none"
            />
          )}
        </div>

        <label
          htmlFor="floatingInput2"
          className="absolute text-color-gray-light duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-focus:px-2 peer-focus:text-[#616161] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4  bg-white px-1  left-2"
        >
          Tags
        </label>
      </div>

      {/* Page URL Input */}
      {/* <div className="relative w-full text-xs">
        <input
          type="text"
          id="floatingInput3"
          name="footprintUrl"
          value={footPrint?.footprintUrl}
          disabled={isReadOnly}
          onChange={handleChange}
          placeholder=" "
          className="block w-full px-2.5 pb-2.5 pt-4 rounded-lg border border-[#A1A1A1] focus:outline-none  peer"
        />
        <label
          htmlFor="floatingInput3"
          className="absolute text-gray-700 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#616161] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5"
        >
          Start Page URL
        </label>
      </div> */}
      <div className="relative w-full text-xs">
        <input
          type="text"
          id="floatingInput3"
          name="footprintUrl"
          value={footPrint?.footprintUrl}
          disabled={isReadOnly}
          onChange={handleChange}
          placeholder=" "
          className="block w-full px-2.5 pb-2.5 pt-4 rounded-lg border bg-white border-[#A1A1A1] focus:outline-none peer"
        />
        <label
          htmlFor="floatingInput3"
          className="absolute text-color-gray-light duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white px-1  left-2"
        >
          Start Page URL
        </label>
      </div>
    </div>
  );
};

export default FloatingInput;
