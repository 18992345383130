import React, { useEffect } from "react";
import { useState } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import FailToast from "./Toast/FailToast";
import { baseUrl } from "../config";
// import testtube from "../components/Images/TestTube.png";
import testtube from "../components/Images/Testtube logo.png";
import Loading from "./Loading/Loading";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import Intercom from "@intercom/messenger-js-sdk";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectPath, setRedirectPath] = useState("");
  // const from = location.state?.from?.pathname || redirectPath;
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [activeFail, setActiveFail] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState("");

  window.intercomSettings = {
    app_id: "x5w0eskb",
    custom_launcher_selector: "#help",
  };
  // const [activeSuccess, setActiveSuccess] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // check onboarding status
  const checkOnboardStatus = async (userId) => {
    try {
      const response = await axios.get(`${baseUrl}/user/${userId}`);

      console.log("Get request successful!", response.data);
      const { onboarded } = response.data;

      let from = location.state?.from?.pathname || "";
      if (onboarded) {
        from = location.state?.from?.pathname || "/dashboard";
      } else {
        from = location.state?.from?.pathname || "/onboarding";
      }

      navigate(from, { replace: true });
      console.log("invoking navigate");
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 3000);
    }
  }, [activeFail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const emailError = validateEmail(formData.email)
      ? ""
      : "That doesn’t look like a correct email address";
    // const passwordError = validatePassword(formData.password)
    //   ? ""
    //   : "Passwords in Foot Print are 8 characters minumum.";

    console.log("emailError", emailError);
    if (emailError) {
      setErrors({ email: emailError });

      setLoading(false);
    } else {
      console.log("Form submitted:", formData);

      try {
        // Gtag implementation
        window.dataLayer = window.dataLayer || [];

        const response = await axios.post(`${baseUrl}/user/login`, formData);

        console.log("Post request successful!", response.data);
        const token = response.data.token;
        const decoded = jwtDecode(token);
        const { username, userId } = decoded;
        localStorage.setItem("Token", response.data.token);
        localStorage.setItem("username", username);
        localStorage.setItem("userId", userId);

        window.dataLayer.push({
          event: "signIn",
          userId: userId,
          signInMethod: "email", // or other methods like 'google', 'facebook'
        });

        await checkOnboardStatus(userId);
        setLoading(false);
      } catch (error) {
        if (error.response) {
          // alert("Error In Submitting: " + error.response.data.msg);
          // toast.error(error.response.data.msg);
          setActiveFail(true);
          setLoading(false);
          // setValue(error.response.data.msg);
          setValue(error.response.data.msg);
        } else {
          alert("Error In Submitting: " + error.message);
          setLoading(false);
        }
      }
    }
  };

  const googleLogin = useGoogleLogin({
    scope:
      "https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.gender.read",
    onSuccess: async ({ access_token }) => {
      try {
        console.log(access_token);
        const userInfo = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
        console.log(userInfo, "new");

        await GoogleSignInFunc(userInfo);
      } catch (error) {
        console.log(error);

        console.log(error);
      }
    },
    onFailure: () => {
      console.log("error");
    },

    // onSuccess: (codeResponse) => setUser(codeResponse),
    // onError: (error) => console.log('Login Failed:', error)
  });

  const SetUser = async (response) => {
    const token = response.data.token;
    console.log(token, "this is checking ");
    const decoded = jwtDecode(token);
    const { username, userId } = decoded;
    localStorage.setItem("Token", token);
    localStorage.setItem("username", username);
    localStorage.setItem("userId", userId);
    console.log("mai mil gya", username, userId);

    if (response?.data?.user) {
      // navigate("/dashboard");
      const userId = response?.data?.user?._id;

      await checkOnboardStatus(userId);
    } else {
      console.log("error in setting user");
    }
  };

  const GoogleSignInFunc = async (userInfo) => {
    const mailCheck = await checkEmail(userInfo?.data?.email);
    if (mailCheck) {
      SetUser(mailCheck);
      return;
    }

    const googleUser = {
      firstname: userInfo?.data?.given_name,
      email: userInfo?.data?.email,
    };

    await SignUp(googleUser, "google");
    return;
  };

  const logOut = () => {
    googleLogout();
  };

  const checkEmail = async (email) => {
    try {
      const response = await axios.post(`${baseUrl}/user/checkemail`, {
        email,
      });

      if (response?.data?.success === true) {
        return response;
      }
      // console.log(response);
      return null;
    } catch (err) {
      console.log(err);
    }
  };

  const SignUp = async (socialUser, type) => {
    const config = {
      host: `${baseUrl}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${baseUrl}`,
      },
    };

    try {
      let response = null;
      if (type === "google") {
        response = await axios.post(
          `${baseUrl}/user/googlesignup`,
          {
            username: socialUser?.firstname,
            email: socialUser?.email,
          },
          config
        );
      }

      console.log(response);

      if (response?.data?.success === true) {
        await SetUser(response);
      } else if (response?.data?.success === false) {
        //   toast.error(response?.data?.msg, toastOption);
        console.log(response?.data?.msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="bg-[#252525]  flex items-center justify-center min-h-screen ">
      <div className="">
        {activeFail && (
          <FailToast
            errorValue={value}
            activeFail={activeFail}
            setActiveFail={setActiveFail}
          />
        )}
        <div className="flex w-96 flex-col space-y-0 rounded-lg border py-5 px-5 mt-2   mx-auto bg-[#FFFFFF]">
          <div className="">
            <div className="">
              {/* <h1 class=" text-2xl font-extrabold text-color-gray-light-700 ">Test Tube</h1> */}
              <img src={testtube} alt="testtube logo" className="h-[42px]" />
            </div>

            <div className="pt-[24px]">
              <p class=" font-semibold text-2xl text-color-gray-light ">
                Sign in
              </p>
            </div>
            <p class="text-color-gray-light font-normal	text-base tracking-wide pt-[8px]	">
              Enter Your credentials to Sign in
            </p>
          </div>

          <div className="pt-[24px]">
            <div class="relative  w-full ">
              <input
                type="text"
                id="email"
                name="email"
                onChange={handleChange}
                class="border-1 peer block w-full appearance-none rounded-lg border border-[#A1A1A1] bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-color-gray-light-900 focus:border-gray-300 focus:outline-none focus:ring-0"
                placeholder=" "
              />
              <label
                for="email"
                class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-color-gray-light-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-color-gray-light-600"
              >
                {" "}
                Email{" "}
              </label>
            </div>
            {errors.email && (
              <p className="text-red-300 font-normal text-xs pt-1">
                {errors.email}
              </p>
            )}
          </div>

          <div className="pt-[8px]">
            <div class="relative mt-1 w-full">
              <input
                type="password"
                id="password"
                name="password"
                onChange={handleChange}
                class="border-1 peer block w-full appearance-none rounded-lg border border-[#A1A1A1] bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-color-gray-light-900 focus:border-gray-300 focus:outline-none focus:ring-0"
                placeholder=" "
              />
              <label
                for="password"
                class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-color-gray-light-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-color-gray-light-600"
              >
                {" "}
                Password
              </label>
            </div>
            {errors.password && (
              <p className="text-red-300 font-normal text-xs pt-1">
                {errors.password}
              </p>
            )}
          </div>

          <label class="inline-flex items-center pt-[8px]" for="grayCheckBox">
            <input
              id="grayCheckBox"
              type="checkbox"
              class="w-4 h-4 accent-gray-600"
            />
            <span class="ml-4">Keep me signed in</span>
          </label>
          <div className="pt-[16px]">
            <button
              id="button_login"
              className="rounded-lg h-[40px]  bg-button-bg py-2 border border-[#414141]  w-full font-bold text-white hover:bg-button-hover "
              onClick={handleSubmit}
            >
              {loading ? <Loading /> : "Sign in"}
            </button>
          </div>
          <div className="pt-[12px]">
            <p class=" text-sm text-color-gray-light ">
              Not a member?
              <Link
                to="/signUp"
                class="font-bold leading-6 text-color-gray-light hover:text-color-gray-light"
              >
                {" "}
                Sign up
              </Link>
            </p>
            <p class=" text-sm text-color-gray-light ">
              Forgot Password ?
              <Link
                to="/reset-password"
                class="font-bold leading-6 text-color-gray-light hover:text-color-gray-light"
              >
                {" "}
                Reset now
              </Link>
            </p>
          </div>

          <div className="mb-4">
            <div className="flex items-center">
              <div className="flex-grow h-px bg-gray-300"></div>
              <div className="mx-4 text-center dark:text-primary light:text-primary">
                Or
              </div>
              <div className="flex-grow h-px bg-gray-300"></div>
            </div>

            <div className="flex justify-center mt-2  gap-2">
              <button
                id="button_googleLogin"
                onClick={() => {
                  googleLogin();
                }}
                className="flex h-[40px]   justify-center items-center w-full  gap-2 py-2 bg-white border  hover:bg-gray-100 rounded-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#FFC107"
                    d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                  ></path>
                  <path
                    fill="#FF3D00"
                    d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                  ></path>
                  <path
                    fill="#4CAF50"
                    d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                  ></path>
                  <path
                    fill="#1976D2"
                    d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                  ></path>
                </svg>
                <span className=" font-semibold text-[#414141] text-base">
                  Continue with Google
                </span>
              </button>
            </div>
          </div>
          <div class="flex flex-row space-x-2 pt-[24px]">
            <a id="help">
              <p
                class="font-normal text-xs text-color-gray-light cursor-pointer"
                onClick={() => Intercom("showNewMessage")}
              >
                Help
              </p>
            </a>

            <a href="https://testtube.io/privacy-policy/" target="_blank">
              <p class="font-normal text-xs text-color-gray-light">Privacy</p>
            </a>
            <a href="https://testtube.io/terms-of-use/" target="_blank">
              <p class="font-normal text-xs text-color-gray-light">Terms</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
