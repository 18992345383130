import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";
import Guide1 from "../components/Guide/Guide1";
import Guide2 from "../components/Guide/Guide2";
import Guide3 from "../components/Guide/Guide3";
import Popup_Card from "../components/Card/Popup_Card";
import Footprint_Guide_Card from "../components/Card/Footprint_Guide_Card";
import Footprint_Guide from "../components/Guide/Footprint_Guide";
import Footprint_Guide_Finish from "../components/Guide/Footprint_Guide_Finish";
import Footprint_Guide_Mid from "../components/Guide/Footprint_Guide_Mid";
import Popup_End_Card from "../components/Card/Popup_End_Card";
import Footprint_Home from "../components/Footprint/Footprint_Home";
import axios from "axios";
import FootprintInfoPage from "./FootprintInfoPage";
import FootprintHomePage from "./FootprintHomePage";
import { baseUrl } from "../config";
import Extension from "../components/Modal/Extension";

const Dashboard = () => {
  const [hasCreatedFootprint, setHasCreatedFootprint] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [website, setWebsite] = useState();
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userId");

  const checkUserFootprint = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseUrl}/footprint/foot/user/${userId}`
      );

      console.log("user has created any footprint", response.data);
      // setUser(response.data);
      // setHasCreatedFootprint(response.data);
      console.log("Response from bbbb", response.data.website);
      setHasCreatedFootprint(response.data.hasFootprint);
      setWebsite(response.data.website[0]);
      setLoading(false);
    } catch (error) {
      console.log("Error in fetching data", error.response);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userId) {
      checkUserFootprint();
    }
  }, [userId]);

  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  useEffect(() => {
    // Listener for messages from the extension
    const messageListener = (event) => {
      if (event.source !== window) return;
      if (event.data.type === "EXTENSION_INSTALLED") {
        console.log("Extension is installed:", event.data.data);
        setIsExtensionInstalled(true);
      }
    };

    // Add event listener for messages
    window.addEventListener("message", messageListener);

    // Send a message to check if the extension is installed
    window.postMessage({ type: "CHECK_EXTENSION" }, "*");

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  return (
    <>
      {/* <Extension show={isModalOpen} /> */}
      {/* <Extension
        show={!isExtensionInstalled}
        setIsExtensionInstalled={setIsExtensionInstalled}
      /> */}
      <div className="guides">
        <div className="inner-guides">
          {/* {hasCreatedFootprint ? (
            <FootprintHomePage />
          ) : (
            <div className="container flex justify-center  m-auto h-[50vh] w-[60vw] ">
              <Guide1 />
            </div>
          )}  */}
          {loading ? (
            <div className="flex justify-center h-screen items-center	">
              <div class="border-gray-300 h-10 w-10 animate-spin rounded-full border-4 border-t-grsy-400" />
            </div>
          ) : hasCreatedFootprint ? (
            <FootprintHomePage />
          ) : (
            <div className="container flex justify-center  m-auto h-[50vh] w-[60vw]  ">
              <Guide1 />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
