import React, { useEffect, useState } from "react";
import { FaRegCircle, FaCheckCircle } from "react-icons/fa";

const Guide2_Input = ({ onInputChange, activeFail, data }) => {
  console.log("data hu mai ", data);
  const [inputValue, setInputValue] = useState("");
  const [isFilled, setIsFilled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validateInput = (value) => {
    const domainPattern = /^[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/; // Basic pattern for domain validation
    const existingDomains = ["google.com", "example.com"]; // Add the list of domains already in use here

    if (existingDomains.includes(value)) {
      setErrorMessage(
        "That website is already part of your or someone else's account."
      );
      setIsFilled(false);
    } else if (!domainPattern.test(value)) {
      setErrorMessage("That doesn't look like a website url.");
      setIsFilled(false);
    } else {
      setErrorMessage("");
      setIsFilled(true);
    }
  };

  const handleInputChange = (e) => {
    let value = e.target.value.trim();
    value = value
      .replace(/^https?:\/\/(www\.)?|^www\./, "")
      .replace(/\/+$/, "");
    setInputValue(value);
    validateInput(value);
    onInputChange(value);
  };

  useEffect(() => {
    if (data) {
      setInputValue(data); // Set the input value if data exists
    }
  }, [data]);

  useEffect(() => {
    if (activeFail) {
      setErrorMessage("Please enter your website url");
    }
  }, [activeFail]);
  return (
    <>
      <div className="p-1">
        <div className="flex flex-col ">
          <div className="flex items-center ml-2 mt-4">
            <button className="focus:outline-none">
          
                <FaRegCircle className="text-gray-500" />
            </button>
            <div className="text-color-gray-light font-semibold mx-2 ">
              Enter your website URL
            </div>
          </div>

          <div className="input-info mx-2">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Enter your website URL"
              className={`w-full pl-2 pr-4 py-2 rounded-lg border ${
                errorMessage ? "" : "border-[#A1A1A1]"
              } focus:outline-none focus:border-[#A1A1A1] my-2`}
            />
            <p className="text-xs mx-2">
              Example: google.com. Do not enter sub-domains or https:// or www.
            </p>
            {!isFilled && errorMessage && (
              <p className="text-[12px] mx-2 text-xs text-[#F7716E]">
                {errorMessage}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Guide2_Input;
