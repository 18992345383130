import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

// const PrivateRoute = ({ element: Element, ...rest }) => {
const PrivateRoute = ({ children }) => {
  const [isAuth, setIsAuth] = useState(1);
  const location = useLocation();
  const token = localStorage.getItem("Token");
  console.log("token", token);

  useEffect(() => {
    verifyToken();
  }, []);

  console.log("isAuth", isAuth);
  const verifyToken = () => {
    if (!token) {
      setIsAuth(false);
      localStorage.setItem("isAuth", false);
    }
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        setIsAuth(false);
        localStorage.setItem("isAuth", false);
      } else {
        setIsAuth(true);
        localStorage.setItem("isAuth", true);
      }
    } catch (error) {
      setIsAuth(false);
      localStorage.setItem("isAuth", false);
      console.error("Error decoding token:", error);
    }
  };

  // return isAuth ? <Outlet /> : <Navigate to="/login" />;
  if (!isAuth) {
    return <Navigate to="/signIn" state={{ from: location }} />;
  }
  return children;
};

export default PrivateRoute;
