import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../config";
const Status_Small_Card = ({ footprintId }) => {
  const [data, setData] = useState();
  let pendingRuns =
    data?.occurrences - (data?.successCount + data?.failureCount);

  useEffect(() => {
    // Fetch data from the API and set the input field values
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/footprint/foot/${footprintId}`
        );
        const footprint = response.data;
        setData(footprint);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="container ">
      <div className="wrapper  w-[25vw] h-[45vh] border rounded-xl bg-white">
        <div className="top-section flex justify-between items-center mx-4 my-2">
          <div className="content  font-semibold text-xl ">
            <h3> Status </h3>
          </div>
        </div>
        <div className="mid-section rounded-xl  mx-2">
          <div className="count-info ">
            <div className="inner-count-info flex flex-col gap-2">
              <div className="content text-sm mx-1 bg-[#F1F1F1] rounded-lg  p-2">
                <div className="icon-section flex text-color-gray-light gap-1">
                  <svg
                    className="mt-1"
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.25065 1.0835H0.583984V5.75016H5.25065V4.00016H6.41732V12.1668H8.75065V13.9168H13.4173V9.25016H8.75065V11.0002H7.58398V4.00016H8.75065V5.75016H13.4173V1.0835H8.75065V2.8335H5.25065V1.0835ZM12.2507 2.25016H9.91732V4.5835H12.2507V2.25016ZM9.91732 10.4168H12.2507V12.7502H9.91732V10.4168Z"
                      fill="#616161"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.25065 1.0835H0.583984V5.75016H5.25065V4.00016H6.41732V12.1668H8.75065V13.9168H13.4173V9.25016H8.75065V11.0002H7.58398V4.00016H8.75065V5.75016H13.4173V1.0835H8.75065V2.8335H5.25065V1.0835ZM12.2507 2.25016H9.91732V4.5835H12.2507V2.25016ZM9.91732 10.4168H12.2507V12.7502H9.91732V10.4168Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.25065 1.0835H0.583984V5.75016H5.25065V4.00016H6.41732V12.1668H8.75065V13.9168H13.4173V9.25016H8.75065V11.0002H7.58398V4.00016H8.75065V5.75016H13.4173V1.0835H8.75065V2.8335H5.25065V1.0835ZM12.2507 2.25016H9.91732V4.5835H12.2507V2.25016ZM9.91732 10.4168H12.2507V12.7502H9.91732V10.4168Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.25065 1.0835H0.583984V5.75016H5.25065V4.00016H6.41732V12.1668H8.75065V13.9168H13.4173V9.25016H8.75065V11.0002H7.58398V4.00016H8.75065V5.75016H13.4173V1.0835H8.75065V2.8335H5.25065V1.0835ZM12.2507 2.25016H9.91732V4.5835H12.2507V2.25016ZM9.91732 10.4168H12.2507V12.7502H9.91732V10.4168Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.25065 1.0835H0.583984V5.75016H5.25065V4.00016H6.41732V12.1668H8.75065V13.9168H13.4173V9.25016H8.75065V11.0002H7.58398V4.00016H8.75065V5.75016H13.4173V1.0835H8.75065V2.8335H5.25065V1.0835ZM12.2507 2.25016H9.91732V4.5835H12.2507V2.25016ZM9.91732 10.4168H12.2507V12.7502H9.91732V10.4168Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </svg>
                  <h3 className="text-color-gray-light font-bold">
                    Total Runs
                  </h3>
                </div>
                <p className="text-xl mt-1 font-bold">
                  {data?.successCount + data?.failureCount}
                </p>
              </div>

              <div className="content text-sm mx-1 bg-[#F1F1F1] rounded-lg  p-2">
                <div className="icon-section flex text-color-gray-light items-center gap-1">
              
          
                    <svg
                      className=""
                      width="12"
                      height="12"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 5H10V10H5V12H12V5Z" fill="#414141" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10Z"
                        fill="#414141"
                      />
                    </svg>

                  <h3 className="text-color-gray-light font-bold">
                    Pending Runs
                  </h3>
                </div>
                <p className="text-xl mt-1 font-bold">{pendingRuns}</p>
              </div>

              <div className="bottom-info flex">
                <div className="content text-sm mx-1 border border-[#C5F2C7] rounded-lg mt-1 p-2 w-1/2">
                  <div className="icon-section flex text-color-gray-light items-center gap-2">
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.99935 6.33317C3.27549 6.33317 3.49935 6.55703 3.49935 6.83317V11.0832C3.49935 11.3593 3.27549 11.5832 2.99935 11.5832H1.66602C1.38987 11.5832 1.16602 11.3593 1.16602 11.0832V6.83317C1.16602 6.55703 1.38987 6.33317 1.66602 6.33317H2.99935ZM5.24935 12.7498C4.93993 12.7498 4.64318 12.6269 4.42439 12.4081C4.2056 12.1893 4.08268 11.8926 4.08268 11.5832V6.33317C4.08268 6.01234 4.21102 5.72067 4.42685 5.51067L7.91163 2.0206C8.10685 1.82508 8.42364 1.82496 8.619 2.02033L8.88351 2.28484C9.04102 2.44234 9.14018 2.65817 9.14018 2.89734L9.12268 3.084L8.69361 5.14807C8.62908 5.4585 8.86608 5.74984 9.18314 5.74984H11.666C12.3135 5.74984 12.8327 6.27484 12.8327 6.9165V8.08317C12.8327 8.23484 12.8035 8.37484 12.751 8.509L10.9893 12.0382C10.8143 12.4582 10.4002 12.7498 9.91602 12.7498H5.24935ZM5.24935 11.0832C5.24935 11.3593 5.47321 11.5832 5.74935 11.5832H9.62311C9.81322 11.5832 9.98688 11.4754 10.0712 11.305L11.6141 8.18801C11.6483 8.11905 11.666 8.04314 11.666 7.96619V7.4165C11.666 7.14036 11.4422 6.9165 11.166 6.9165H7.73921C7.42125 6.9165 7.18406 6.62363 7.25012 6.31262L7.42966 5.46736C7.53314 4.98015 6.9384 4.65773 6.58661 5.01033L5.39539 6.20429C5.30187 6.29803 5.24935 6.42503 5.24935 6.55744V11.0832Z"
                        fill="#616161"
                      />
                      <path
                        d="M2.99935 6.33317C3.27549 6.33317 3.49935 6.55703 3.49935 6.83317V11.0832C3.49935 11.3593 3.27549 11.5832 2.99935 11.5832H1.66602C1.38987 11.5832 1.16602 11.3593 1.16602 11.0832V6.83317C1.16602 6.55703 1.38987 6.33317 1.66602 6.33317H2.99935ZM5.24935 12.7498C4.93993 12.7498 4.64318 12.6269 4.42439 12.4081C4.2056 12.1893 4.08268 11.8926 4.08268 11.5832V6.33317C4.08268 6.01234 4.21102 5.72067 4.42685 5.51067L7.91163 2.0206C8.10685 1.82508 8.42364 1.82496 8.619 2.02033L8.88351 2.28484C9.04102 2.44234 9.14018 2.65817 9.14018 2.89734L9.12268 3.084L8.69361 5.14807C8.62908 5.4585 8.86608 5.74984 9.18314 5.74984H11.666C12.3135 5.74984 12.8327 6.27484 12.8327 6.9165V8.08317C12.8327 8.23484 12.8035 8.37484 12.751 8.509L10.9893 12.0382C10.8143 12.4582 10.4002 12.7498 9.91602 12.7498H5.24935ZM5.24935 11.0832C5.24935 11.3593 5.47321 11.5832 5.74935 11.5832H9.62311C9.81322 11.5832 9.98688 11.4754 10.0712 11.305L11.6141 8.18801C11.6483 8.11905 11.666 8.04314 11.666 7.96619V7.4165C11.666 7.14036 11.4422 6.9165 11.166 6.9165H7.73921C7.42125 6.9165 7.18406 6.62363 7.25012 6.31262L7.42966 5.46736C7.53314 4.98015 6.9384 4.65773 6.58661 5.01033L5.39539 6.20429C5.30187 6.29803 5.24935 6.42503 5.24935 6.55744V11.0832Z"
                        fill="black"
                        fill-opacity="0.2"
                      />
                      <path
                        d="M2.99935 6.33317C3.27549 6.33317 3.49935 6.55703 3.49935 6.83317V11.0832C3.49935 11.3593 3.27549 11.5832 2.99935 11.5832H1.66602C1.38987 11.5832 1.16602 11.3593 1.16602 11.0832V6.83317C1.16602 6.55703 1.38987 6.33317 1.66602 6.33317H2.99935ZM5.24935 12.7498C4.93993 12.7498 4.64318 12.6269 4.42439 12.4081C4.2056 12.1893 4.08268 11.8926 4.08268 11.5832V6.33317C4.08268 6.01234 4.21102 5.72067 4.42685 5.51067L7.91163 2.0206C8.10685 1.82508 8.42364 1.82496 8.619 2.02033L8.88351 2.28484C9.04102 2.44234 9.14018 2.65817 9.14018 2.89734L9.12268 3.084L8.69361 5.14807C8.62908 5.4585 8.86608 5.74984 9.18314 5.74984H11.666C12.3135 5.74984 12.8327 6.27484 12.8327 6.9165V8.08317C12.8327 8.23484 12.8035 8.37484 12.751 8.509L10.9893 12.0382C10.8143 12.4582 10.4002 12.7498 9.91602 12.7498H5.24935ZM5.24935 11.0832C5.24935 11.3593 5.47321 11.5832 5.74935 11.5832H9.62311C9.81322 11.5832 9.98688 11.4754 10.0712 11.305L11.6141 8.18801C11.6483 8.11905 11.666 8.04314 11.666 7.96619V7.4165C11.666 7.14036 11.4422 6.9165 11.166 6.9165H7.73921C7.42125 6.9165 7.18406 6.62363 7.25012 6.31262L7.42966 5.46736C7.53314 4.98015 6.9384 4.65773 6.58661 5.01033L5.39539 6.20429C5.30187 6.29803 5.24935 6.42503 5.24935 6.55744V11.0832Z"
                        fill="black"
                        fill-opacity="0.2"
                      />
                      <path
                        d="M2.99935 6.33317C3.27549 6.33317 3.49935 6.55703 3.49935 6.83317V11.0832C3.49935 11.3593 3.27549 11.5832 2.99935 11.5832H1.66602C1.38987 11.5832 1.16602 11.3593 1.16602 11.0832V6.83317C1.16602 6.55703 1.38987 6.33317 1.66602 6.33317H2.99935ZM5.24935 12.7498C4.93993 12.7498 4.64318 12.6269 4.42439 12.4081C4.2056 12.1893 4.08268 11.8926 4.08268 11.5832V6.33317C4.08268 6.01234 4.21102 5.72067 4.42685 5.51067L7.91163 2.0206C8.10685 1.82508 8.42364 1.82496 8.619 2.02033L8.88351 2.28484C9.04102 2.44234 9.14018 2.65817 9.14018 2.89734L9.12268 3.084L8.69361 5.14807C8.62908 5.4585 8.86608 5.74984 9.18314 5.74984H11.666C12.3135 5.74984 12.8327 6.27484 12.8327 6.9165V8.08317C12.8327 8.23484 12.8035 8.37484 12.751 8.509L10.9893 12.0382C10.8143 12.4582 10.4002 12.7498 9.91602 12.7498H5.24935ZM5.24935 11.0832C5.24935 11.3593 5.47321 11.5832 5.74935 11.5832H9.62311C9.81322 11.5832 9.98688 11.4754 10.0712 11.305L11.6141 8.18801C11.6483 8.11905 11.666 8.04314 11.666 7.96619V7.4165C11.666 7.14036 11.4422 6.9165 11.166 6.9165H7.73921C7.42125 6.9165 7.18406 6.62363 7.25012 6.31262L7.42966 5.46736C7.53314 4.98015 6.9384 4.65773 6.58661 5.01033L5.39539 6.20429C5.30187 6.29803 5.24935 6.42503 5.24935 6.55744V11.0832Z"
                        fill="black"
                        fill-opacity="0.2"
                      />
                      <path
                        d="M2.99935 6.33317C3.27549 6.33317 3.49935 6.55703 3.49935 6.83317V11.0832C3.49935 11.3593 3.27549 11.5832 2.99935 11.5832H1.66602C1.38987 11.5832 1.16602 11.3593 1.16602 11.0832V6.83317C1.16602 6.55703 1.38987 6.33317 1.66602 6.33317H2.99935ZM5.24935 12.7498C4.93993 12.7498 4.64318 12.6269 4.42439 12.4081C4.2056 12.1893 4.08268 11.8926 4.08268 11.5832V6.33317C4.08268 6.01234 4.21102 5.72067 4.42685 5.51067L7.91163 2.0206C8.10685 1.82508 8.42364 1.82496 8.619 2.02033L8.88351 2.28484C9.04102 2.44234 9.14018 2.65817 9.14018 2.89734L9.12268 3.084L8.69361 5.14807C8.62908 5.4585 8.86608 5.74984 9.18314 5.74984H11.666C12.3135 5.74984 12.8327 6.27484 12.8327 6.9165V8.08317C12.8327 8.23484 12.8035 8.37484 12.751 8.509L10.9893 12.0382C10.8143 12.4582 10.4002 12.7498 9.91602 12.7498H5.24935ZM5.24935 11.0832C5.24935 11.3593 5.47321 11.5832 5.74935 11.5832H9.62311C9.81322 11.5832 9.98688 11.4754 10.0712 11.305L11.6141 8.18801C11.6483 8.11905 11.666 8.04314 11.666 7.96619V7.4165C11.666 7.14036 11.4422 6.9165 11.166 6.9165H7.73921C7.42125 6.9165 7.18406 6.62363 7.25012 6.31262L7.42966 5.46736C7.53314 4.98015 6.9384 4.65773 6.58661 5.01033L5.39539 6.20429C5.30187 6.29803 5.24935 6.42503 5.24935 6.55744V11.0832Z"
                        fill="black"
                        fill-opacity="0.2"
                      />
                    </svg>

                    <h3 className="text-color-gray-light font-bold">
                      Successful Runs
                    </h3>
                  </div>
                  <p className="text-xl mt-1 font-bold">{data?.successCount}</p>
                </div>
                <div className="content text-sm mx-1 border border-[#FCD0CF] rounded-lg mt-1 p-2 w-1/2">
                  <div className="icon-section flex text-color-gray-light items-center gap-2">
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.9993 8.66667C10.7232 8.66667 10.4993 8.44281 10.4993 8.16667V3.91667C10.4993 3.64052 10.7232 3.41667 10.9993 3.41667H12.3327C12.6088 3.41667 12.8327 3.64052 12.8327 3.91667V8.16667C12.8327 8.44281 12.6088 8.66667 12.3327 8.66667H10.9993ZM8.74935 2.25C9.05877 2.25 9.35551 2.37292 9.57431 2.59171C9.7931 2.8105 9.91602 3.10725 9.91602 3.41667V8.66667C9.91602 8.9875 9.78768 9.27917 9.57185 9.48917L6.08707 12.9792C5.89185 13.1748 5.57506 13.1749 5.37969 12.9795L5.11518 12.715C4.95768 12.5575 4.85852 12.3417 4.85852 12.0967L4.87602 11.9158L5.30509 9.85176C5.36962 9.54133 5.13262 9.25 4.81555 9.25H2.33268C1.68518 9.25 1.16602 8.725 1.16602 8.08333V6.91667C1.16602 6.765 1.19518 6.625 1.24768 6.49083L3.00935 2.96167C3.18435 2.54167 3.59852 2.25 4.08268 2.25H8.74935ZM8.74935 3.91667C8.74935 3.64052 8.52549 3.41667 8.24935 3.41667H4.37559C4.18548 3.41667 4.01182 3.52448 3.92748 3.69485L2.38458 6.81183C2.35044 6.88079 2.33268 6.9567 2.33268 7.03364V7.58333C2.33268 7.85948 2.55654 8.08333 2.83268 8.08333H6.25366C6.57161 8.08333 6.80881 8.3762 6.74275 8.68722L6.56247 9.53597C6.45904 10.0229 7.05312 10.3454 7.40511 9.99341L8.6029 8.79561C8.69667 8.70184 8.74935 8.57467 8.74935 8.44206V3.91667Z"
                        fill="#616161"
                      />
                      <path
                        d="M10.9993 8.66667C10.7232 8.66667 10.4993 8.44281 10.4993 8.16667V3.91667C10.4993 3.64052 10.7232 3.41667 10.9993 3.41667H12.3327C12.6088 3.41667 12.8327 3.64052 12.8327 3.91667V8.16667C12.8327 8.44281 12.6088 8.66667 12.3327 8.66667H10.9993ZM8.74935 2.25C9.05877 2.25 9.35551 2.37292 9.57431 2.59171C9.7931 2.8105 9.91602 3.10725 9.91602 3.41667V8.66667C9.91602 8.9875 9.78768 9.27917 9.57185 9.48917L6.08707 12.9792C5.89185 13.1748 5.57506 13.1749 5.37969 12.9795L5.11518 12.715C4.95768 12.5575 4.85852 12.3417 4.85852 12.0967L4.87602 11.9158L5.30509 9.85176C5.36962 9.54133 5.13262 9.25 4.81555 9.25H2.33268C1.68518 9.25 1.16602 8.725 1.16602 8.08333V6.91667C1.16602 6.765 1.19518 6.625 1.24768 6.49083L3.00935 2.96167C3.18435 2.54167 3.59852 2.25 4.08268 2.25H8.74935ZM8.74935 3.91667C8.74935 3.64052 8.52549 3.41667 8.24935 3.41667H4.37559C4.18548 3.41667 4.01182 3.52448 3.92748 3.69485L2.38458 6.81183C2.35044 6.88079 2.33268 6.9567 2.33268 7.03364V7.58333C2.33268 7.85948 2.55654 8.08333 2.83268 8.08333H6.25366C6.57161 8.08333 6.80881 8.3762 6.74275 8.68722L6.56247 9.53597C6.45904 10.0229 7.05312 10.3454 7.40511 9.99341L8.6029 8.79561C8.69667 8.70184 8.74935 8.57467 8.74935 8.44206V3.91667Z"
                        fill="black"
                        fill-opacity="0.2"
                      />
                      <path
                        d="M10.9993 8.66667C10.7232 8.66667 10.4993 8.44281 10.4993 8.16667V3.91667C10.4993 3.64052 10.7232 3.41667 10.9993 3.41667H12.3327C12.6088 3.41667 12.8327 3.64052 12.8327 3.91667V8.16667C12.8327 8.44281 12.6088 8.66667 12.3327 8.66667H10.9993ZM8.74935 2.25C9.05877 2.25 9.35551 2.37292 9.57431 2.59171C9.7931 2.8105 9.91602 3.10725 9.91602 3.41667V8.66667C9.91602 8.9875 9.78768 9.27917 9.57185 9.48917L6.08707 12.9792C5.89185 13.1748 5.57506 13.1749 5.37969 12.9795L5.11518 12.715C4.95768 12.5575 4.85852 12.3417 4.85852 12.0967L4.87602 11.9158L5.30509 9.85176C5.36962 9.54133 5.13262 9.25 4.81555 9.25H2.33268C1.68518 9.25 1.16602 8.725 1.16602 8.08333V6.91667C1.16602 6.765 1.19518 6.625 1.24768 6.49083L3.00935 2.96167C3.18435 2.54167 3.59852 2.25 4.08268 2.25H8.74935ZM8.74935 3.91667C8.74935 3.64052 8.52549 3.41667 8.24935 3.41667H4.37559C4.18548 3.41667 4.01182 3.52448 3.92748 3.69485L2.38458 6.81183C2.35044 6.88079 2.33268 6.9567 2.33268 7.03364V7.58333C2.33268 7.85948 2.55654 8.08333 2.83268 8.08333H6.25366C6.57161 8.08333 6.80881 8.3762 6.74275 8.68722L6.56247 9.53597C6.45904 10.0229 7.05312 10.3454 7.40511 9.99341L8.6029 8.79561C8.69667 8.70184 8.74935 8.57467 8.74935 8.44206V3.91667Z"
                        fill="black"
                        fill-opacity="0.2"
                      />
                      <path
                        d="M10.9993 8.66667C10.7232 8.66667 10.4993 8.44281 10.4993 8.16667V3.91667C10.4993 3.64052 10.7232 3.41667 10.9993 3.41667H12.3327C12.6088 3.41667 12.8327 3.64052 12.8327 3.91667V8.16667C12.8327 8.44281 12.6088 8.66667 12.3327 8.66667H10.9993ZM8.74935 2.25C9.05877 2.25 9.35551 2.37292 9.57431 2.59171C9.7931 2.8105 9.91602 3.10725 9.91602 3.41667V8.66667C9.91602 8.9875 9.78768 9.27917 9.57185 9.48917L6.08707 12.9792C5.89185 13.1748 5.57506 13.1749 5.37969 12.9795L5.11518 12.715C4.95768 12.5575 4.85852 12.3417 4.85852 12.0967L4.87602 11.9158L5.30509 9.85176C5.36962 9.54133 5.13262 9.25 4.81555 9.25H2.33268C1.68518 9.25 1.16602 8.725 1.16602 8.08333V6.91667C1.16602 6.765 1.19518 6.625 1.24768 6.49083L3.00935 2.96167C3.18435 2.54167 3.59852 2.25 4.08268 2.25H8.74935ZM8.74935 3.91667C8.74935 3.64052 8.52549 3.41667 8.24935 3.41667H4.37559C4.18548 3.41667 4.01182 3.52448 3.92748 3.69485L2.38458 6.81183C2.35044 6.88079 2.33268 6.9567 2.33268 7.03364V7.58333C2.33268 7.85948 2.55654 8.08333 2.83268 8.08333H6.25366C6.57161 8.08333 6.80881 8.3762 6.74275 8.68722L6.56247 9.53597C6.45904 10.0229 7.05312 10.3454 7.40511 9.99341L8.6029 8.79561C8.69667 8.70184 8.74935 8.57467 8.74935 8.44206V3.91667Z"
                        fill="black"
                        fill-opacity="0.2"
                      />
                      <path
                        d="M10.9993 8.66667C10.7232 8.66667 10.4993 8.44281 10.4993 8.16667V3.91667C10.4993 3.64052 10.7232 3.41667 10.9993 3.41667H12.3327C12.6088 3.41667 12.8327 3.64052 12.8327 3.91667V8.16667C12.8327 8.44281 12.6088 8.66667 12.3327 8.66667H10.9993ZM8.74935 2.25C9.05877 2.25 9.35551 2.37292 9.57431 2.59171C9.7931 2.8105 9.91602 3.10725 9.91602 3.41667V8.66667C9.91602 8.9875 9.78768 9.27917 9.57185 9.48917L6.08707 12.9792C5.89185 13.1748 5.57506 13.1749 5.37969 12.9795L5.11518 12.715C4.95768 12.5575 4.85852 12.3417 4.85852 12.0967L4.87602 11.9158L5.30509 9.85176C5.36962 9.54133 5.13262 9.25 4.81555 9.25H2.33268C1.68518 9.25 1.16602 8.725 1.16602 8.08333V6.91667C1.16602 6.765 1.19518 6.625 1.24768 6.49083L3.00935 2.96167C3.18435 2.54167 3.59852 2.25 4.08268 2.25H8.74935ZM8.74935 3.91667C8.74935 3.64052 8.52549 3.41667 8.24935 3.41667H4.37559C4.18548 3.41667 4.01182 3.52448 3.92748 3.69485L2.38458 6.81183C2.35044 6.88079 2.33268 6.9567 2.33268 7.03364V7.58333C2.33268 7.85948 2.55654 8.08333 2.83268 8.08333H6.25366C6.57161 8.08333 6.80881 8.3762 6.74275 8.68722L6.56247 9.53597C6.45904 10.0229 7.05312 10.3454 7.40511 9.99341L8.6029 8.79561C8.69667 8.70184 8.74935 8.57467 8.74935 8.44206V3.91667Z"
                        fill="black"
                        fill-opacity="0.2"
                      />
                    </svg>

                    <h3 className="text-color-gray-light font-bold">
                      Failed Runs
                    </h3>
                  </div>
                  <p className="text-xl mt-1 font-bold">{data?.failureCount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Status_Small_Card;
