import React, { useEffect, useState } from "react";
import Footprint_Guide_Card from "../Card/Footprint_Guide_Card";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../config";
import Setting from "../Settings/Setting";
const Setting_Guide = () => {
  const userId = localStorage.getItem("userId");
  const [website, setWebsite] = useState();

  const fetchWebsiteByUserId = async () => {
    try {
      const response = await axios.get(`${baseUrl}/websites/user/${userId}`);
      console.log(
        "fetch user website from sidebarrrrrr",
        response.data.websites.at(-1)
      );
      setWebsite(response.data.websites.at(-1));
      // setUser(response.data);
      // setHasCreatedFootprint(response.data);
    } catch (error) {
      console.log("Error in fetching data from sidebar", error.response);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchWebsiteByUserId();
    }
  }, [userId]);

  // console.log("websiteId", website._id);

  return (
    <>
      <div className="header-section">
        <div className="info">
          <div className="guide-info flex text-color-gray-light mx-44">
            <Link to={`/${website?._id}/footprint-all-list`}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_7594_235054)">
                  <path
                    d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                    fill="#616161"
                  />
                  <path
                    d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                    fill="black"
                    fill-opacity="0.2"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7594_235054">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(8 8)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </Link>

            <h2 className=" font-bold text-[24px] ">Settings</h2>
          </div>

          <div className="setup-guide mt-5">
            <div className="inner-setup-guide text-color-gray-light">
              <Setting />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting_Guide;
