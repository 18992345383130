import Footprint_Video_Card from "./Card/Footprint_Video_Card";
import Footprint_Summary from "./Card/Footprint_Summary";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../config";
const VideoDesign = () => {
  const location = useLocation();

  // Access the passed state data
  const { videoUrl, logUrl, run, websiteId, footprintId, videoId } =
    location.state || {};
  console.log(
    "videoUrl",
    videoUrl,
    "logUrl",
    logUrl,
    "run",
    run,
    "footprintId",
    footprintId,
    "websiteId",
    websiteId,
    "videoId",
    videoId
  );
  // const [logs, setLogs] = useState("");
  const [summaryLogs, setSummaryLogs] = useState("");
  const [summary, setSummary] = useState("");
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [video, setVideo] = useState();
  const [actionLog, setActionLog] = useState();
  const [copied, setCopied] = useState(false);
  const [copiedSummary, setCopiedSummary] = useState(false);
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [logs, setLogs] = useState([]);

  // Function to fetch and parse log data from S3
  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await fetch(logUrl);
        const text = await response.text();
        const logLines = text.split("\n").filter(Boolean); // Split log by lines and filter out empty lines

        const parsedLogs = logLines.map((log) => {
          const match = log.match(/\[(\d+)ms\]/); // Extract the milliseconds from the log string
          const milliseconds = match ? parseInt(match[1], 10) : 0;
          const content = log.split(" - ").slice(2).join(" - "); // Extract log content after milliseconds
          return { milliseconds, content };
        });
        setLogs(parsedLogs);
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    fetchLogs();
  }, [logUrl]);

  // Function to handle time updates from the video
  const handleTimeUpdate = () => {
    const video = videoRef.current;
    if (video) {
      setCurrentTime(video.currentTime * 1000); // Convert video time to milliseconds
    }
  };

  // Play/Pause Video
  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Seek video to a specific time
  const seekTo = (time) => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = time / 1000; // Convert milliseconds to seconds
    }
  };

  // Get the logs that should be visible based on the video time
  const getVisibleLogs = () => {
    return logs?.filter((log) => currentTime >= log.milliseconds);
  };

  const visibleLogs = getVisibleLogs();

  const fetchVideo = async () => {
    try {
      const response = await axios.get(`${baseUrl}/videos/${videoId}`);

      console.log("Get request for video successful!", response.data);
      if (response?.data?.summaryUrl) {
        await fetchSummariseLogs(response?.data?.summaryUrl);
      }
      setVideo(response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  const handleSummarizeLogs = async () => {
    setIsSummarizing(true); // Set loading state
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.post(`${baseUrl}/ai/summary`, {
        logs: actionLog,
        userId,
        websiteId,
        footprintId,
        videoId,
      });

      // Assuming the API returns the summary in the response
      setSummary(response.data.summary);
      setIsSummarizing(false); // Unset loading state
      setIsHide(true);
      window.location.reload();
    } catch (error) {
      console.error("Error summarizing logs:", error);
      setSummary("Failed to summarize logs.");
      setIsSummarizing(false); // Unset loading state
    }
  };

  const fetchActionLogs = async () => {
    try {
      const response = await fetch(logUrl);
      console.log(response);
      const logText = await response.text();
      setActionLog(logText);
    } catch (error) {
      console.error("Error fetching logs:", error);
      setActionLog("Failed to load logs.....");
    }
  };

  const fetchSummariseLogs = async (logs) => {
    try {
      const response = await fetch(logs);
      console.log("response from sumamry Url", response);
      const summaryLogs = await response.text();
      setSummaryLogs(summaryLogs);
    } catch (error) {
      console.error("Error fetching logs:", error);
      setLogs("Failed to load logs.....");
    }
  };

  console.log(logs, logUrl, "hii data");
  useEffect(() => {
    fetchVideo();
    fetchActionLogs();
  }, [logUrl, videoId]);

  const copyToSummary = () => {
    navigator.clipboard
      .writeText(summaryLogs)
      .then(() => {
        // alert("Logs copied to clipboard!");
        setCopiedSummary(true); // Show "Copied!" message
        setTimeout(() => {
          setCopiedSummary(false); // Hide after 2 seconds
        }, 1500);
      })
      .catch((err) => {
        console.error("Failed to copy logs: ", err);
      });
  };

  const copyToClipboard = () => {
    // Check if logs is an object, then map to extract only 'content' fields
    const logString = Array.isArray(logs)
      ? logs.map((log) => log.content).join("\n") // Join the content with new lines
      : logs;

    navigator.clipboard
      .writeText(logString)
      .then(() => {
        // alert("Logs copied to clipboard!");
        setCopied(true); // Show "Copied!" message
        setTimeout(() => {
          setCopied(false); // Hide after 2 seconds
        }, 1500);
      })
      .catch((err) => {
        console.error("Failed to copy logs: ", err);
      });
  };

  return (
    <>
      <div className="header-section">
        <div className="info">
          <div className="setup-guide mt-5 flex gap-2 justify-between">
            <div className="inner-setup-guide flex flex-col gap-6 text-color-gray-light w-8/12 flex-1">
              <div className="container flex-1">
                <div className="wrapper w-full h-full border rounded-xl flex flex-col bg-white">
                  <div className="flex-grow">
                    <video
                      ref={videoRef}
                      className="w-full h-full object-cover rounded-md"
                      controls
                      src={videoUrl && videoUrl}
                      onTimeUpdate={handleTimeUpdate}
                      alt="Test Case Video"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="right-inner-setup-guide text-color-gray-light w-4/12 flex flex-col gap-4 flex-1">
              <div className="container flex-1">
                <div className="wrapper w-full h-full border rounded-xl bg-white">
                  <h2 className="text-xl font-semibold p-4 text-color-gray-light">
                    Summary Logs
                    <button onClick={copyToSummary} className="ml-2">
                      <i className="fa-solid fa-copy"></i>
                    </button>
                    {copiedSummary && (
                      <button className="text-gray-700 text-sm mt-1 ml-2">
                        Copied!!
                      </button>
                    )}
                  </h2>
                  <div className="p-4 h-60   overflow-y-auto">
                    <pre className="text-sm text-color-gray-light whitespace-pre-wrap">
                      {summaryLogs ? (
                        summaryLogs
                      ) : (
                        <div className="flex justify-center items-center">
                          <button
                            onClick={handleSummarizeLogs}
                            // className="mt-4 bg-green-500 text-white py-2 px-4 my-auto rounded hover:bg-green-600"
                            className="button bg-button-bg text-white text-sm rounded-lg p-2 flex items-center space-x-2 my-12"
                          >
                            {isSummarizing
                              ? "Summarizing..."
                              : "Summarize Logs"}
                          </button>
                        </div>
                      )}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="setup-guide mt-5 flex justify-between">
            <div className="wrapper w-full max-w-[80vw] h-auto min-h-[58vh] border rounded-xl flex flex-col bg-white">
              <div className="flex-grow">
                {logs && (
                  <div className="p-4 h-full">
                    <h2 className="text-xl font-semibold text-color-gray-light mb-4">
                      Action Logs
                      <button onClick={copyToClipboard} className="ml-2">
                        <i className="fa-solid fa-copy"></i>
                      </button>
                      {copied && (
                        <button className="text-color-gray-light text-sm mt-1 ml-2">
                          Copied!!
                        </button>
                      )}
                    </h2>
                    {/* Scrollable logs */}
                    <div className="max-h-[40vh] overflow-y-auto overflow-x-auto">
                      <pre className="text-sm text-color-gray-light whitespace-pre-wrap break-words">
                        {visibleLogs.map((log, index) => (
                          <div
                            key={index}
                            className={`log-entry ${
                              currentTime >= log.milliseconds
                                ? "highlighted"
                                : ""
                            }`}
                            onClick={() => seekTo(log.milliseconds)}
                          >
                            {log.content}
                          </div>
                        ))}
                      </pre>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoDesign;
