import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import FailToast from "./Toast/FailToast";
// import SuccessToast from "./Toast/SuccessToast";
import { baseUrl, hostedUrl } from "../config";
import testtube from "../components/Images/Testtube logo.png";
import Loading from "./Loading/Loading";
import Intercom from "@intercom/messenger-js-sdk";
// import { emailVerification } from "../Helper/Verification";
const Register = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [activeFail, setActiveFail] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    username: "",
  });

  window.intercomSettings = {
    app_id: "x5w0eskb",
    custom_launcher_selector: "#help",
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    // Password should be at least 8 characters long
    return password.length >= 8;
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const verifyEmail = async (email) => {
    try {
      // Replace with your proxy server or direct API endpoint
      const response = await axios.post(`${baseUrl}/verify/emailValid`, {
        email,
      });
      console.log("response", response.data);
      return response.data.valid;
    } catch (error) {
      console.error("Error verifying email:", error.message);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    window.dataLayer = window.dataLayer || [];
    const emailError = validateEmail(formData.email)
      ? ""
      : "Invalid email format";
    const passwordError = validatePassword(formData.password)
      ? ""
      : "Please enter at least 8 characters as your password";

    if (emailError || passwordError) {
      setErrors({ email: emailError, password: passwordError });
      setLoading(false);
    } else {
      const isEmailValid = await verifyEmail(formData.email);
      if (!isEmailValid) {
        setErrors({ ...errors, email: "Email is not active" });
        setLoading(false);
        return;
      }
      try {
        const response = await axios.post(`${baseUrl}/user/register`, formData);

        console.log("Post request successful!", response.data);

        setActiveSuccess(true);

        setValue("Successfully registered !!");
        window.dataLayer.push({
          event: "signUp",
          userId: response.data.user.id,
          signUpMethod: "email", // e.g., 'email', 'google', 'facebook'
        });
      } catch (error) {
        if (error.response) {
          setActiveFail(true);
          setValue(error.response.data.msg);
          setLoading(false);
          console.log("error", error.response.data.msg);
        } else {
          alert("Error In Submitting: " + error.message);
          setLoading(false);
        }
      }
    }
  };
  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 2000);
    }
    if (activeSuccess) {
      setTimeout(() => {
        setActiveSuccess(false);
        navigate("/signIn");
      }, 2000);
    }
  }, [activeFail, activeSuccess]);
  return (
    <div className=" bg-[#252525] flex items-center justify-center min-h-screen ">
      <div class="">
        {activeFail && (
          <FailToast
            errorValue={value}
            activeFail={activeFail}
            setActiveFail={setActiveFail}
          />
        )}
        {/* {activeSuccess && (
          <SuccessToast
            successValue={value}
            activeSuccess={activeSuccess}
            setActiveSuccess={setActiveSuccess}
          />
        )} */}

        <div class="flex w-96 flex-col space-y-0 rounded-lg border py-5 px-5 mt-2 mx-auto bg-[#FFFFFF]">
          <div class="">
            {/* <h1 class=" text-2xl font-extrabold text-gray-700">Test Tube</h1> */}
            <div className="">
              {/* <h1 class=" text-2xl font-extrabold text-gray-700 ">Test Tube</h1> */}
              <img src={testtube} alt="testtube logo" className="h-[42px]" />
            </div>
            <div className="pt-[24px]">
              <p class="text-color-gray-light font-semibold text-2xl ">
                Sign Up
              </p>
            </div>

            <p class="text-color-gray-light font-normal	text-base tracking-wide pt-[8px] ">
              Complete the sign up process for a healthy website
            </p>
          </div>

          <div className="pt-[24px]">
            <div class="relative  w-full ">
              <input
                type="text"
                id="username"
                name="username"
                onChange={handleChange}
                class="border-1 peer block w-full appearance-none rounded-lg border border-[#A1A1A1] bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-gray-300 focus:outline-none focus:ring-0"
                placeholder=" "
              />
              <label
                for="username"
                class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-color-gray-light duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-color-gray-light"
              >
                {" "}
                Name{" "}
              </label>
            </div>
          </div>

          <div className="pt-[8px]">
            <div class="relative mt-1 w-full">
              <input
                type="email"
                name="email"
                onChange={handleChange}
                id="useremail"
                class="border-1 peer block w-full appearance-none rounded-lg border border-[#A1A1A1] bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-gray-300 focus:outline-none focus:ring-0"
                placeholder=" "
              />
              <label
                for="email"
                class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-color-gray-light duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-color-gray-light"
              >
                {" "}
                Email{" "}
              </label>
            </div>
            {errors.email && (
              <p className="text-red-300 font-normal text-xs pt-1">
                {errors.email}
              </p>
            )}
          </div>

          <div className="pt-[8px] ">
            <div class="relative mt-1 w-full">
              <input
                type="password"
                name="password"
                onChange={handleChange}
                id="userpassword"
                class="border-1 peer block w-full appearance-none rounded-lg border border-[#A1A1A1] bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-gray-300 focus:outline-none focus:ring-0"
                placeholder=" "
              />
              <label
                for="password"
                class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-color-gray-light duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-color-gray-light"
              >
                {" "}
                Password
              </label>
            </div>
            {errors.password && (
              <p className="text-red-300 font-normal text-xs pt-1">
                {errors.password}
              </p>
            )}
          </div>

          <div className="pt-[16px]">
            <button
              id="button_signup"
              // button-bg
              // className="rounded-lg bg-btn-gradient-auth py-2  w-full font-bold text-white hover:bg-white hover:text-black"
              className="rounded-lg  h-[40px]  bg-button-bg py-2 border border-[#414141]  w-full font-bold text-white hover:bg-button-hover"
              onClick={handleSubmit}
            >
              {/* {loading ? "Signing up...." : "Signup"} */}
              {!activeSuccess && loading ? <Loading /> : "Sign up"}
            </button>
          </div>

          <div className="pt-[8px]">
            <p class="text-color-gray-light font-normal	text-xs ">
              By signing up for TestTube, you agree to our terms of use and our
              privacy policy.
            </p>
          </div>

          <div className="pt-[12px]">
            <p class="text-sm text-color-gray-light  ">
              Already a member?
              <Link
                to="/signIn"
                class="font-bold leading-6 text-color-gray-light hover:text-color-gray-light"
              >
                {" "}
                Sign in
              </Link>
            </p>
          </div>
          <div class="flex flex-row space-x-2 pt-[24px]">
            <a id="help">
              <p
                class="font-normal text-xs text-color-gray-light cursor-pointer"
                onClick={() => Intercom("showNewMessage")}
              >
                Help
              </p>
            </a>
            <a href="https://testtube.io/privacy-policy/" target="_blank">
              <p class="font-normal text-xs text-color-gray-light">Privacy</p>
            </a>
            <a href="https://testtube.io/terms-of-use/" target="_blank">
              <p class="font-normal text-xs text-color-gray-light">Terms</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
