// import React from "react";
// import { CiHome } from "react-icons/ci";
// import { Link } from "react-router-dom";
// import { IoIosNotificationsOutline } from "react-icons/io";
// import { IoSettingsOutline } from "react-icons/io5";

// const Sidebar = ({ setAccountActive, setNotificationActive }) => {
//   const handleAccount = () => {
//     setAccountActive(true);
//     setNotificationActive(false);
//   };

//   const handleNotification = () => {
//     setAccountActive(false);
//     setNotificationActive(true);
//   };

//   return (
//     <div
//       className="flex flex-col  px-4   md:w-64 border-r border-[#E0E0E0] "
//       style={{ height: "512px" }}
//     >
//       <div className=" tracking-widest font-bold hidden md:block text-gray-400  mt-4 mx-2 text-xs">
//         Settings
//       </div>

//       <ul className="flex flex-col mt-5 text-xl">
//         <li
//           className="flex items-center py-2 px-1  space-x-4 text-white text-lg hover:rounded-lg hover:cursor-pointer
//         hover:bg-gray-100 hover:text-white"
//         >
//           <IoSettingsOutline size={24} className="text-gray-600" />

//           <span
//             className="hidden md:inline text-gray-600 text-base font-bold"
//             onClick={handleAccount}
//           >
//             Account
//           </span>
//         </li>
//         <li
//           className="flex items-center py-2 px-1  space-x-4 text-white text-lg hover:rounded-lg hover:cursor-pointer
//         hover:bg-gray-100 hover:text-white"
//         >
//           {/* <CiHome size={20} className="text-gray-600" /> */}
//           <IoIosNotificationsOutline size={24} className="text-gray-600" />
//           <span
//             className="hidden md:inline text-gray-600 text-base font-bold"
//             onClick={handleNotification}
//           >
//             Notification
//           </span>
//         </li>
//         {/* <li
//           className="flex items-center py-2 px-1  space-x-4 text-white text-lg hover:rounded-lg hover:cursor-pointer
//         hover:bg-gray-100 hover:text-white"
//         >
//           <CiHome size={20} className="text-gray-600" />
//           <span className="hidden md:inline text-gray-600 text-base font-bold">
//             <Link to={"/dashboardSites"}>Teams</Link>
//           </span>
//         </li>
//         <li
//           className="flex items-center py-2 px-1  space-x-4 text-white text-lg hover:rounded-lg hover:cursor-pointer
//         hover:bg-gray-100 hover:text-white"
//         >
//           <CiHome size={20} className="text-gray-600" />
//           <span className="hidden md:inline text-gray-600 text-base font-bold">
//             <Link to={"/dashboardSites"}>Billing</Link>
//           </span>
//         </li> */}
//       </ul>
//     </div>
//   );
// };

// export default Sidebar;

import React from "react";
import { CiHome } from "react-icons/ci";
import { Link } from "react-router-dom";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";

const Sidebar = ({ setAccountActive, setNotificationActive, activeItem }) => {
  const handleAccount = () => {
    setAccountActive(true);
    setNotificationActive(false);
  };

  const handleNotification = () => {
    setAccountActive(false);
    setNotificationActive(true);
  };

  return (
    <div
      className="flex flex-col  px-0  border-r border-[#E0E0E0] "
      style={{ height: "512px", width: "350px" }}
    >
      <div className=" tracking-widest font-bold  hidden md:block text-[#818181]  mt-4 ml-4 text-xs">
        SETTINGS
      </div>

      <ul className="flex flex-col mt-5 text-xl">
        <li
          className={`flex items-center py-2 pl-2 space-x-4 text-lg rounded-md cursor-pointer  ${
            activeItem === "account" ? "bg-[#F1F1F1] " : ""
          }`}
        >
          <IoSettingsOutline size={24} className="text-gray-600" />

          <span
            className="hidden md:inline  text-base font-bold"
            onClick={handleAccount}
          >
            Account
          </span>
        </li>
        <li
          className={`flex items-center py-2 pl-2 space-x-4 text-lg rounded-md hover:rounded-md cursor-pointer ${
            activeItem === "notification" ? "bg-gray-100" : ""
          }`}
        >
          {/* <CiHome size={20} className="text-gray-600" /> */}
          <IoIosNotificationsOutline size={24} className="text-gray-600" />
          <span
            className="hidden md:inline  text-base font-bold"
            onClick={handleNotification}
          >
            Notification
          </span>
        </li>
        {/* <li
          className="flex items-center py-2 px-1  space-x-4 text-white text-lg hover:rounded-lg hover:cursor-pointer 
        hover:bg-gray-100 hover:text-white"
        >
          <CiHome size={20} className="text-gray-600" />
          <span className="hidden md:inline text-gray-600 text-base font-bold">
            <Link to={"/dashboardSites"}>Teams</Link>
          </span>
        </li>
        <li
          className="flex items-center py-2 px-1  space-x-4 text-white text-lg hover:rounded-lg hover:cursor-pointer 
        hover:bg-gray-100 hover:text-white"
        >
          <CiHome size={20} className="text-gray-600" />
          <span className="hidden md:inline text-gray-600 text-base font-bold">
            <Link to={"/dashboardSites"}>Billing</Link>
          </span>
        </li> */}
      </ul>
    </div>
  );
};

export default Sidebar;
