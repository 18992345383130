import React from "react";
import ReactDOM from "react-dom";

const FailToast = ({ activeFail, setActiveFail, errorValue }) => {
  return ReactDOM.createPortal(
    <div
      id="toast-success"
      class="flex items-center fixed  w-full max-w-xs  px-3  h-[70px]  text-gray-500 bg-[#F7716E] rounded-lg shadow"
      role="alert"
      style={{ zIndex: 1000, top: "0.8%", left: "38.5%" }}
    >
      <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8  rounded-lg">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.1902 0.21967C17.0496 0.0790176 16.8588 0 16.6599 0H7.3401C7.14119 0 6.95042 0.0790176 6.80977 0.21967L0.21967 6.80977C0.0790176 6.95042 0 7.14119 0 7.3401V16.6599C0 16.8588 0.0790176 17.0496 0.21967 17.1902L6.80977 23.7803C6.95042 23.921 7.14119 24 7.3401 24H16.6599C16.8588 24 17.0496 23.921 17.1902 23.7803L23.7803 17.1902C23.921 17.0496 24 16.8588 24 16.6599V7.3401C24 7.14119 23.921 6.95042 23.7803 6.80977L17.1902 0.21967ZM12 6C11.1969 6 10.5693 6.69343 10.6493 7.49256L11.1754 12.7537C11.2177 13.1774 11.5742 13.5 12 13.5C12.4258 13.5 12.7823 13.1774 12.8246 12.7537L13.3507 7.49256C13.4307 6.69343 12.8031 6 12 6ZM12.0023 15C11.1739 15 10.5023 15.6716 10.5023 16.5C10.5023 17.3284 11.1739 18 12.0023 18C12.8307 18 13.5023 17.3284 13.5023 16.5C13.5023 15.6716 12.8307 15 12.0023 15Z"
            fill="white"
          />
        </svg>
      </div>
      <p class="ml-2 text-sm text-white">{errorValue}</p>
      <button
        type="button"
        class="ml-auto  -my-1.5  text-white  rounded-lg focus:ring-2 focus:ring-gray-300   inline-flex items-center justify-center h-8 w-8 "
        data-dismiss-target="#toast-success"
        aria-label="Close"
        onClick={() => setActiveFail(false)}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.22468 4.81145C5.83416 4.42092 5.201 4.42092 4.81047 4.81145C4.41995 5.20197 4.41995 5.83514 4.81047 6.22566L10.5852 12.0004L4.81053 17.7751C4.42001 18.1656 4.42001 18.7988 4.81053 19.1893C5.20106 19.5798 5.83422 19.5798 6.22474 19.1893L11.9994 13.4146L17.7741 19.1893C18.1646 19.5798 18.7978 19.5798 19.1883 19.1893C19.5788 18.7988 19.5788 18.1656 19.1883 17.7751L13.4136 12.0004L19.1884 6.22566C19.5789 5.83514 19.5789 5.20197 19.1884 4.81145C18.7978 4.42092 18.1647 4.42092 17.7742 4.81145L11.9994 10.5862L6.22468 4.81145Z"
            fill="white"
          />
        </svg>
      </button>
    </div>,
    document.getElementById("toast-root-fail")
  );
};

export default FailToast;
