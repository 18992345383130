import React, { useState, useEffect } from "react";
import { Table, Pagination } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import axios from "axios";
import { baseUrl } from "../../config";
import { Navigate, useNavigate } from "react-router-dom";

// Custom pagination component
const CustomPagination = ({
  current,
  pageSize,
  total,
  onChange,
  onPageSizeChange,
}) => {
  return (
    <div className="flex items-center justify-between p-4">
      <div className="text-sm text-gray-700">
        Showing{" "}
        <b>
          {(current - 1) * pageSize + 1}-{Math.min(current * pageSize, total)}
        </b>{" "}
        of {total} records
      </div>
      <div className="flex items-center space-x-2">
        <select
          value={pageSize}
          onChange={(e) => onPageSizeChange(parseInt(e.target.value, 10))}
          className="rounded px-2 py-1 text-sm"
        >
          <option value={10}>Show 10 rows</option>
          <option value={20}>20 rows</option>
          <option value={50}>50 rows</option>
        </select>
        <Pagination
          current={current}
          pageSize={pageSize}
          total={total}
          onChange={onChange}
          hideOnSinglePage
          showSizeChanger={false}
          prevIcon={
            <div className="bg-[#C5DCFA] text-white rounded-md">
              <LeftOutlined />
            </div>
          }
          nextIcon={
            <div className="bg-[#C5DCFA] text-white rounded-md">
              <RightOutlined />
            </div>
          }
          className="ant-pagination"
          itemRender={(current, type, originalElement) => {
            if (type === "prev") {
              return (
                <div className="bg-[#C5DCFA] text-black rounded-md">
                  <LeftOutlined />
                </div>
              );
            }
            if (type === "next") {
              return (
                <div className="bg-[#C5DCFA] text-black rounded-md">
                  <RightOutlined />
                </div>
              );
            }
            return originalElement;
          }}
        />
      </div>
    </div>
  );
};

const formatDateTime = (isoString) => {
  const date = new Date(isoString);

  const options = { month: "short", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  const optionsTime = { hour: "numeric", minute: "numeric", hour12: true };
  const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

  const dayName = date.toLocaleDateString("en-US", { weekday: "long" });

  return {
    date: `${formattedDate}, at ${formattedTime}`,
    dayName,
  };
};

const columns = [
  {
    title: "Run",
    dataIndex: "run",
    key: "run",
    render: (text) => (
      <span className="font-bold text-color-gray-light">{text}</span>
    ),
  },
  {
    title: "Date & Time",
    dataIndex: "date",
    key: "date",
    render: (text) => (
      <div className="text-sm text-black">
        <div>{text.date}</div>
        {/* <div className="text-xs text-gray-500">{text.dayName}</div> */}
      </div>
    ),
  },
  {
    title: "Result",
    dataIndex: "status",
    key: "status",
    render: (text) => (
      <span
        className={`text-sm font-medium ${text === "Pass" ? "text-[#2AB930]" : "text-red-600"
          }`}
      >
        {text === "Pass" ? "Pass" : "Fail"}{" "}
      </span>
    ),
  },
  {
    title: "Records",
    dataIndex: "records",
    key: "records",
    render: (text) => (
      <span className="text-blue-500 font-semibold hover:underline cursor-pointer">
        {text}
      </span>
    ),
  },
];

const TableList = ({ filter, footprintId, websiteId }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [dataSource, setDataSource] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        // "http://localhost:5000/api/footprint/foot/66bae9dc19babe204a466f1c"
        `${baseUrl}/footprint/foot/${footprintId}`
      ); // Adjust the API endpoint
      const footprint = response.data;

      const data = footprint.videos.map((video, index) => {
        const { date, dayName } = formatDateTime(video.createdAt);
        return {
          key: video._id,
          run: `Run #${index + 1}`,
          date: { date, dayName },
          status: video.status === "success" ? "Pass" : "Fail",
          records: "View Run",
          videoUrl: video.url,
          logUrl: video?.logUrl,
        };
      });

      setDataSource(data);
    };

    fetchData();
  }, []);

  const filteredDataSource = dataSource.filter((item) => {
    if (filter === "All") return true;
    return item.status === filter;
  });

  const onExpand = (expanded, record) => {
    const keys = expanded ? [record.key] : [];
    setExpandedRowKeys(keys);
  };

  const expandedRowRender = (record) => {
    return (
      <div className="m-[-15px]">
        <video width="100%" controls>
          <source src={record.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  };

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, current: page }));
  };

  const handlePageSizeChange = (size) => {
    setPagination((prev) => ({ ...prev, pageSize: size, current: 1 }));
  };
  const handleClick = (record) => {
    console.log("record", record);
    const data = {
      videoUrl: record.videoUrl,
      logUrl: record.logUrl,
      run: record.run,
      websiteId: websiteId,
      footprintId: footprintId,
      videoId: record.key,
    };
    navigate(`/logs`, { state: data });
  };
  return (
    <div className="p-2 mx-2">
      <Table
        columns={columns}
        dataSource={filteredDataSource.slice(
          (pagination.current - 1) * pagination.pageSize,
          pagination.current * pagination.pageSize
        )}
        className="custom-table"
        rowClassName={(record, index) =>
          record.status === "Fail" ? "bg-[#FCD0CF] bg-opacity-50" : ""
        }
        expandable={{
          expandedRowRender,
          expandedRowKeys,
          onExpand,
          expandRowByClick: true,
        }}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                className="!bg-transparent !text-[#E0E0E0] !font-semibold"
              />
            ),
          },
        }}
        pagination={false}
        expandIconColumnIndex={-1}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              // Handle the click event
              // console.log("Row clicked:", record, rowIndex);
              handleClick(record);
            },
          };
        }}
      />
      <CustomPagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={filteredDataSource.length}
        onChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </div>
  );
};

export default TableList;
