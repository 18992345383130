import React, { useState, useEffect } from "react";
import axios from "axios";
import FloatingInput from "../Input/FloatingInput";
import Footprint_Save from "../Footprint/Footprint_Save";
import { baseUrl } from "../../config";
import Intercom from "@intercom/messenger-js-sdk";

const Footprint_Home_Card = ({ showSection }) => {
  const [footPrintSaveCard, setFootPrintSaveCard] = useState(false);
  const [footprintId, setFootprintId] = useState();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState();
   // Determine which URL to use based on user role
const [user,setUser] =useState();

  window.intercomSettings = {
    app_id: "x5w0eskb",
    custom_launcher_selector: "#help",
  };
  const [formData, setFormData] = useState({
    footprintName: "",
    footprintUrl: "",
    tags: "",
    websiteId: "",
    tagsArray: [],
  });

  const urlToSend = user?.role === "admin" ? formData?.footprintUrl : websiteUrl;

  useEffect(() => {
    const messageListener = (event) => {
      if (event.source !== window) return;
      if (event.data.type && event.data.type === "FROM_EXTENSION") {
        console.log("Message from extension:", event.data.data);
        // Handle the message from the extension if needed
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  const createFootprint = async () => {
    // console.log("mai data hu ", formData);
    const token = localStorage.getItem("Token");
    const userId = localStorage.getItem("userId");

    // Send message to content script to start tracking

    window.postMessage(
      {
        type: "START_TRACKING",
        url: formData.footprintUrl,
        userId: userId,
        websiteId: formData.websiteId,
        footprintName: formData.footprintName,
        tags: formData.tags,
        websiteUrl: urlToSend,
        tagsArray: formData.tagsArray,
      },
      "*"
    );

    console.log(
      "Start tracking message sent to extension",
      formData,
      websiteUrl
    );
  };

  const fetchUserWebsite = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(`${baseUrl}/user/${userId}`);
      console.log("mai response hu", response);
      setUser(response.data);
      const website = response.data?.websites;
      const lastWebsite = website[website.length - 1];
      setFormData((prevFormData) => ({
        ...prevFormData,
        websiteId: lastWebsite,
      }));
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  const fetchWebsiteByUserId = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(`${baseUrl}/websites/user/${userId}`);
      console.log(
        "fetch user website for extension",
        response.data.websites[0].url
      );
      setWebsiteUrl(response.data?.websites[0].url);
      // setUser(response.data);
      // setHasCreatedFootprint(response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  useEffect(() => {
    fetchUserWebsite();
    fetchWebsiteByUserId();
  }, []);

  // useEffect(() => {
  //   const isValid = formData.footprintUrl.includes(websiteUrl);
  //   setIsButtonEnabled(isValid);
  // }, [websiteUrl, formData.footprintUrl]);

  useEffect(() => {
    if (user?.role === "admin") {
      setIsButtonEnabled(true);
    } else {
      const isValid = formData.footprintUrl.includes(websiteUrl);
      setIsButtonEnabled(isValid);
    }
  }, [websiteUrl, formData.footprintUrl, user]);

  return (
    <>
      <div className="container ">
        <div className="wrapper  w-[50vw] h-[55vh] border border-[#E0E0E0] rounded-xl bg-white my-2">
          <div className="top-section flex justify-between items-center mx-4 my-4">
            <div className="content text-color-gray-light font-bold  text-xl ">
              <h3 className="text-lg">New Test</h3>
            </div>
          </div>

          <FloatingInput
            formData={formData}
            setFormData={setFormData}
            isEditable={true}
            isReadOnly={false}
          />
          <div className="p-1">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="ml-3 flex  items-center gap-3 my-2">
                  <button
                    id="button_create_test"
                    className={`button text-xs rounded-lg p-2 flex items-center space-x-2 mt-2 ${
                      isButtonEnabled
                        ? "bg-button-bg text-white"
                        : "bg-button-disable text-[#C0C0C0] border-[#C5C5C5]"
                    }`}
                    onClick={createFootprint}
                    disabled={!isButtonEnabled}
                  >
                    <span>Create Test</span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.6401 7.02539H12.0186V5.02539H19.0186V12.0254H17.0186V8.4754L12.1046 13.3893L10.6904 11.9751L15.6401 7.02539Z"
                        fill="#C0C0C0"
                      />
                      <path
                        d="M10.9824 6.97485H4.98242V18.9749H16.9824V12.9749H14.9824V16.9749H6.98242V8.97485H10.9824V6.97485Z"
                        fill="#C0C0C0"
                      />
                    </svg>
                  </button>
                  <a id="help " className="mt-1">
                    <span
                      className="text-xs mt-2 text-black cursor-pointer"
                      onClick={() => Intercom("showNewMessage")}
                    >
                      Connect with us for Help
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {footPrintSaveCard && (
        <Footprint_Save
          footprintId={footprintId}
          websiteId={formData.websiteId}
        />
      )} */}
    </>
  );
};

export default Footprint_Home_Card;
