import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SuccessToast from "./Toast/SuccessToast";
import { baseUrl } from "../config";
// import testtube from "../components/Images/TestTube.png";
import testtube from "../components/Images/Testtube logo.png";
import Intercom from "@intercom/messenger-js-sdk";

const ChangePassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  window.intercomSettings = {
    app_id: "x5w0eskb",
    custom_launcher_selector: "#help",
  };
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [value, setValue] = useState("");
  const [errors, setErrors] = useState("");
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { password, confirmPassword } = formData;
    console.log("password", password, "confirmPassword", confirmPassword);
    if (password != confirmPassword) {
      alert("Password is not same");
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/user/reset-password/${token}`,
        formData
      );
      console.log("Post request successful!", response.data.msg);

      setValue(response.data.msg);
      setActiveSuccess(true);
    } catch (error) {
      console.log("response", error.response.status);
      if (error.response.status === 401) {
        navigate("/reset-password");
      }
    }
  };

  useEffect(() => {
    if (activeSuccess) {
      setTimeout(() => {
        setActiveSuccess(false);
        navigate("/signIn");
      }, 3000);
    }
  }, [activeSuccess]);
  return (
    <div className="bg-[#252525] flex items-center justify-center min-h-screen ">
      <div class="p-12">
        {activeSuccess && (
          <SuccessToast
            successValue={value}
            activeSuccess={activeSuccess}
            setActiveSuccess={setActiveSuccess}
          />
        )}
        <div class="flex w-96 flex-col space-y-0 rounded-lg border py-5 px-5 mt-2  mx-auto  bg-[#FFFFFF]">
          <div class="">
            {/* <h1 class=" text-2xl font-bold text-gray-700">Footprint</h1> */}
            <div className="">
              {/* <h1 class=" text-2xl font-extrabold text-color-gray-light-700 ">Test Tube</h1> */}
              <img src={testtube} alt="testtube logo" className="h-[42px]" />
            </div>
            <div className="pt-[24px]">
              <p class="text-color-gray-light font-semibold text-2xl ">
                Reset Password
              </p>
            </div>

            <p class="text-color-gray-light font-normal	text-base pt-[8px]	">
              Complete this to update your password
            </p>
          </div>

          <div className="pt-[24px]">
            <div class="relative mt-1 w-full">
              <input
                type="password"
                id="New Password"
                name="password"
                onChange={handleChange}
                class="border-1 peer block w-full appearance-none rounded-lg border border-gray-300 bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-gray-300 focus:outline-none focus:ring-0"
                placeholder=" "
              />
              <label
                for="password"
                class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-color-gray-light duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-color-gray-light"
              >
                {" "}
                New Password
              </label>
            </div>
            {errors.password && (
              <p className="text-red-300 font-normal text-xs pt-1">
                {errors.password}
              </p>
            )}
          </div>
          <div className="pt-[8px]">
            <div class="relative mt-1 w-full">
              <input
                type="password"
                id="Confirm Password"
                name="confirmPassword"
                onChange={handleChange}
                class="border-1 peer block w-full appearance-none rounded-lg border border-gray-300 bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-gray-300 focus:outline-none focus:ring-0"
                placeholder=" "
              />
              <label
                for="password"
                class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-color-gray-light duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-color-gray-light"
              >
                {" "}
                Confirm Password
              </label>
            </div>
            {errors.password && (
              <p className="text-red-300 font-normal text-xs pt-1">
                {errors.password}
              </p>
            )}
          </div>
          <div className="pt-[16px]">
            <button
              className="rounded-lg  h-[40px]  bg-button-bg py-2 border border-[#414141]  w-full font-bold text-white hover:bg-button-hover"
              onClick={handleSubmit}
            >
              Reset Password
            </button>
          </div>

          <div class="flex flex-row space-x-2 pt-[24px]">
            <a id="help">
              <p
                class="font-normal text-xs text-color-gray-light cursor-pointer"
                onClick={() => Intercom("showNewMessage")}
              >
                Help
              </p>
            </a>{" "}
            <a href="https://testtube.io/privacy-policy/" target="_blank">
              <p class="font-normal text-xs text-color-gray-light">Privacy</p>
            </a>
            <a href="https://testtube.io/terms-of-use/" target="_blank">
              <p class="font-normal text-xs text-color-gray-light">Terms</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
