import React, { useEffect, useState } from "react";
import { FaCircle, FaRegCircle } from "react-icons/fa";
import Guide2_Input from "../Input/Guide2_Input";
import Guide3 from "../Guide/Guide3";
import axios from "axios";
import { baseUrl } from "../../config";
import FailToast from "../Toast/FailToast";
import { FaCheckCircle } from "react-icons/fa";
import Popup_Card from "./Popup_Card";
import Intercom from "@intercom/messenger-js-sdk";
const Card = ({ showSection }) => {
  const [isFilled, setIsFilled] = useState(false);
  const [websiteInput, setWebsiteInput] = useState(false);
  const [isGuide3, setGuide3] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [websiteId, setWebsiteId] = useState();
  const [websiteDetails, setWebsiteDetails] = useState();
  const [onboardWebsiteId, setOnboardWebsiteId] = useState(false);
  const userId = localStorage.getItem("userId");
  const [activeFail, setActiveFail] = useState(false);

  window.intercomSettings = {
    app_id: "x5w0eskb",
    custom_launcher_selector: "#help",
  };
  const toggleIcon = () => {
    setIsFilled(!isFilled);
  };

  const handleWebsiteUrl = () => {
    setWebsiteInput(true);
  };

  const value = "Please Enter Your Website Url !";

  const checkUserWebsite = async () => {
    try {
      const response = await axios.get(`${baseUrl}/websites/user/${userId}`);

      console.log("response from website", response.data?.websites);
      // setWebsiteId(response.data.websites?.[0]._id);
      setWebsiteId(
        response.data.websites.length > 0 ? response.data.websites[0]._id : ""
      );

      if (response.data.websites.length > 0) {
        setGuide3(true);
        showSection(true);
      }

      // alert(response.data.msg);
    } catch (error) {
      // alert(error.response.data.msg);
    }
  };

  useEffect(() => {
    if (userId) {
      checkUserWebsite();
    }
  }, [userId, onboardWebsiteId]);

  const addWebsite = async () => {
    console.log("Input value:", inputValue);
    setActiveFail(true);

    if (!activeFail) {
      console.log("Input value:", inputValue);
      const token = localStorage.getItem("Token");
      console.log("token data is done", token, "------------------------");

      // api call
      if (token) {
        try {
          const response = await axios.post(
            `${baseUrl}/websites/`,
            { url: inputValue },
            {
              headers: { authorization: `Bearer ${token}` },
            }
          );
          console.log("response0-----------------", response.data);
          setGuide3(true);
          setInputValue("");
          showSection(true);
          setOnboardWebsiteId(true);
          // alert(response.data.msg);
        } catch (error) {
          // alert(error.response.data.msg);
        }
      }
    }
  };

  const validatePage = () => {
    if (inputValue.length > 1) return inputValue.length > 1;
  };

  const updateWebsite = async () => {
    console.log("Input value:", inputValue);
    const token = localStorage.getItem("Token");

    // api call
    if (token) {
      try {
        const response = await axios.put(
          `${baseUrl}/websites/${websiteId}`,
          { url: inputValue },
          {
            headers: { authorization: `Bearer ${token}` },
          }
        );
        console.log("response", response.data);
        setGuide3(true);
        setInputValue("");
        showSection(true);
        setOnboardWebsiteId(true);
        // alert(response.data.msg);
      } catch (error) {
        // alert(error.response.data.msg);
      }
    }
  };

  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 3000);
    }
  }, [activeFail]);

  // testing purpose
  // const [isFilled, setIsFilled] = useState(false);
  // const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [website, setWebsite] = useState();
  const [showPop, setShowPopup] = useState(false);

  const handlePopup = () => {
    console.log(inputValue, "try");
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const validateInput = (value) => {
    // Updated pattern to validate full URLs
    const urlPattern =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    const existingDomains = ["google.com", "example.com"]; // Add the list of domains already in use here

    try {
      const url = new URL(value); // Parse the input as a URL to extract the hostname

      if (existingDomains.includes(url.hostname)) {
        setErrorMessage(
          "That website is already part of your or someone else’s account."
        );
        setIsFilled(false);
      } else if (!urlPattern.test(value)) {
        setErrorMessage("That doesn’t look like a website URL.");
        setIsFilled(false);
      } else {
        setErrorMessage("");
        setIsFilled(true);
      }
    } catch (error) {
      // If URL parsing fails, the input is not a valid URL
      setErrorMessage("That doesn’t look like a website URL.");
      setIsFilled(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.trim();

    console.log("input footprint", value);
    setInputValue(value);
    validateInput(value);
  };

  const fetchWebsiteByUserId = async () => {
    try {
      const response = await axios.get(`${baseUrl}/websites/${websiteId}`);
      console.log("fetch user website from sidebar", response.data);
      setWebsiteDetails(response.data);
      // setUser(response.data);
      // setHasCreatedFootprint(response.data);
    } catch (error) {
      console.log("Error in fetching data from sidebar", error.response);
    }
  };

  useEffect(() => {
    if (websiteId) fetchWebsiteByUserId();
  }, []);

  const goBack = () => {
    setGuide3(false);
    handleWebsiteUrl();
    fetchWebsiteByUserId();
  };

  return (
    <>
      <div className="container ">
        {isGuide3 && (
          <div className="guide-info flex text-color-gray-light">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={goBack}
            >
              <g clip-path="url(#clip0_7594_235054)">
                <path
                  d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                  fill="#616161"
                />
                <path
                  d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                  fill="black"
                  fill-opacity="0.2"
                />
                <path
                  d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                  fill="black"
                  fill-opacity="0.2"
                />
              </g>
              <defs>
                <clipPath id="clip0_7594_235054">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(8 8)"
                  />
                </clipPath>
              </defs>
            </svg>

            <h2 className=" font-bold text-[24px]">Add a website</h2>
          </div>
        )}
        <div className="wrapper bg-[#ffffff]  w-[50vw] h-auto min-h-[55vh] border rounded-xl">
          <div className="top-section flex justify-between items-center mx-2 mt-6">
            <div className="content text-color-gray-light font-semibold text-xl space-y-1.5 ">
              <h3 className="text-lg">Setup Guide </h3>
              <p className="text-xs text-color-gray-light">
                We'll hand hold you through the process of adding your website
                and then setting up your footprints.
              </p>
            </div>
          </div>

          {isGuide3 ? (
            <div className="count-info my-2">
              <div className="inner-count-info">
                <div className="content text-color-gray-light mx-2">
                  <button className="button bg-white border rounded-md text-color-gray-light text-xs   p-1">
                    1/3 completed
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="count-info my-2">
              <div className="inner-count-info">
                <div className="content text-color-gray-light mx-2">
                  <button className="button bg-white border border-input-border rounded-[4px] text-color-gray-light text-[10px] px-1.5 py-0.5  tracking-widest font-normal">
                    0/3 COMPLETED
                  </button>
                </div>
              </div>
            </div>
          )}

          {isGuide3 ? (
            <>
              <div className="confirmation-box">
                <div className="flex items-center justify-between">
                  <div className="flex items-center mx-2 my-2">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0303 4.96967C11.7374 4.67678 11.2626 4.67678 10.9697 4.96967C10.9626 4.97674 10.9559 4.98424 10.9498 4.9921L7.4774 9.41674L5.38388 7.32322C5.09098 7.03033 4.61611 7.03033 4.32322 7.32322C4.03032 7.61612 4.03032 8.09099 4.32322 8.38388L6.96966 11.0303C7.26256 11.3232 7.73743 11.3232 8.03032 11.0303C8.03685 11.0238 8.043 11.0169 8.04876 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967Z"
                        fill="#52D858"
                      />
                    </svg>

                    <div className="text-color-gray-light font-semibold ml-2">
                      Add your website
                    </div>
                  </div>
                </div>
              </div>

              <div className="mid-section rounded-xl bg-[#F1F1F1] mx-2 my-2">
                <div className="p-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <button className="focus:outline-none">
                        <FaRegCircle className="text-gray-500" />
                      </button>
                      <div className="text-color-gray-light font-semibold ml-2">
                        Create your 1st test case
                      </div>
                    </div>
                  </div>
                  <div className="input-info mx-2">
                    <input
                      type="text"
                      placeholder=""
                      value={inputValue}
                      onChange={handleInputChange}
                      className="w-full pl-2 pr-4 py-2 rounded-lg border border-borderColor  focus:outline-none focus:border-[#A1A1A1]  my-2"
                    />
                    {errorMessage && (
                      <p className="text-[12px] text-[#F7716E]">
                        {errorMessage}
                      </p>
                    )}

                    <p className="text-xs">
                      Example:Please enter url with sub-domains or https:// or
                      www.
                    </p>
                  </div>
                </div>

                <div className="p-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="ml-2 flex items-center gap-2 my-2">
                        <button
                          className="button bg-button-bg text-white text-xs rounded-lg p-2 hover:bg-button-hover




"
                          disabled={!isFilled}
                          onClick={handlePopup}
                        >
                          Create Test
                        </button>
                        <a id="help">
                          <span
                            className="text-xs cursor-pointer"
                            onClick={() => Intercom("showNewMessage")}
                          >
                            Connect with us for Help
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="mid-section rounded-xl bg-[#F1F1F1] mx-2 my-4">
              {/* Guide -1 */}
              {!websiteInput && (
                <>
                  <div className="">
                    <div className="flex flex-col px-3 py-4">
                      <div className="flex items-center   gap-2 ">
                        <button className="focus:outline-none">
                          <FaRegCircle className="text-color-gray-light" />
                        </button>
                        <div className="text-color-gray-light font-semibold">
                          Add your website
                        </div>
                      </div>
                      <div className="">
                        <div className="text-color-gray-light text-xs my-2 font-semibold">
                          Add your 1st website and verify your domain with is.
                          This indicates to us that this website belongs to you.
                        </div>
                      </div>

                      <div className="flex items-center gap-4 ">
                        <button
                          className="button bg-button-bg text-white rounded-md p-2 text-xs hover:bg-button-hover




"
                          onClick={handleWebsiteUrl}
                        >
                          Add Website
                        </button>
                        <a id="help">
                          <span
                            className="text-xs cursor-pointer text-black"
                            onClick={() => Intercom("showNewMessage")}
                          >
                            Connect with us for Help
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <div className="p-1">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center"></div>
                    </div>
                  </div> */}
                </>
              )}

              {/*Guide -2 */}
              {websiteInput && (
                <>
                  <Guide2_Input
                    disableButton={toggleIcon}
                    onInputChange={setInputValue}
                    activeFail={activeFail}
                    data={websiteDetails?.url}
                  />

                  <div className="p-1">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <div className="ml-2 flex items-center gap-2 mb-4">
                          <button
                            id="button_add_website"
                            className="button bg-button-bg text-white rounded-md p-2 text-xs hover:bg-button-hover"
                            // onClick={addWebsite}
                            onClick={
                              websiteDetails ? updateWebsite : addWebsite
                            }

                            // disabled={!validatePage()}
                          >
                            {websiteDetails ? "Update Website" : "Add Website"}
                          </button>
                          <a id="help">
                            <span
                              className="text-xs cursor-pointer text-black"
                              onClick={() => Intercom("showNewMessage")}
                            >
                              Connect with us for Help
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {!isGuide3 && (
            <div className="mid-section rounded-xl  mx-2 ">
              <div className="p-1">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <button onClick={toggleIcon} className="focus:outline-none">
                      <FaRegCircle className="text-color-gray-light" />
                    </button>
                    <div className="ml-2">
                      <div className="text-color-gray-light font-semibold">
                        Create your 1st test case
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="mid-section rounded-xl  mx-2 mb-1">
            <div className="p-1">
              <div className="flex items-center justify-between">
                <div className="flex items-center mb-4">
                  <button className="focus:outline-none">
                    <FaRegCircle className="text-color-gray-light" />
                  </button>
                  <div className="ml-2 ">
                    <div className="text-color-gray-light font-semibold">
                      Schedule your test case
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {isGuide3 && <Guide3 websiteId={websiteId} />} */}
      {/* Popup with overlay */}
      {showPop && (
        <>
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>{" "}
          {/* Overlay */}
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <Popup_Card
              websiteId={websiteId}
              footprintUrl={inputValue}
              onClose={handleClosePopup}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Card;
