import React from "react";

import Footprint_All_List_Status_Card from "../Card/Footprint_All_List_Status_Card";
import { useParams } from "react-router-dom";
const Footprint_All_List = () => {
  const { websiteId } = useParams();
  return (
    <>
      <div className="header-section">
        <div className="info">
          {/* <div className="guide-info text-color-gray-light">
                        <h2 className=' font-bold text-[24px] mt-2'>Foot Prints</h2>
                    </div> */}
          <div className="setup-guide mt-5 flex justify-between">
            <div className="inner-setup-guide flex flex-col gap-6 text-color-gray-light w-full">
              <Footprint_All_List_Status_Card websiteId={websiteId} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footprint_All_List;
