// import React, { useState } from "react";

// const Detail = ({ formData, setFormData }) => {
//   const handleChange = (event) => {
//     const { name, value } = event.target;

//     setFormData({ ...formData, [name]: value });
//     console.log("formData", formData);
//   };

//   return (
//     <>
//       <div class="relative mt-5 w-full">
//         <input
//           type="number"
//           pattern="[0-9]{1}"
//           inputmode="numeric"
//           id="number"
//           name="mobile"
//           class="border-1 peer block w-1/2 appearance-none rounded-lg border border-gray-300 bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-gray-300 focus:outline-none focus:ring-0"
//           placeholder=""
//           onChange={handleChange}
//         />
//         <label
//           for="mobile"
//           class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
//         >
//           {" "}
//           Mobile
//         </label>
//       </div>
//     </>
//   );
// };

// export default Detail;

// import React from "react";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

// const Detail = ({ formData, setFormData }) => {
//   const handlePhoneChange = (value) => {
//     setFormData({ ...formData, mobile: value });
//     console.log("formData", formData);
//   };

//   return (
//     <>
//       <div className="relative mt-5 w-full">
//         <PhoneInput
//           country={"in"} // Default country
//           value={formData.mobile}
//           onChange={handlePhoneChange}
//           inputClass="w-1/2" // Additional styling if needed
//         />
//       </div>
//     </>
//   );
// };

// export default Detail;

import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const Detail = ({ formData, setFormData }) => {
  // const handleChange = (event) => {
  //   const { name, value } = event.target;

  //   setFormData({ ...formData, [name]: value });
  //   console.log("formData", formData);
  // };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, mobile: value });
    console.log("formData", formData);
  };

  return (
    <>
      <div class="relative mt-5 w-full">
        <PhoneInput
          country={"us"} // Default country
          value={formData.mobile}
          onChange={handlePhoneChange}
          inputClass="w-full" // Additional styling if needed
        />
        <label
          for="mobile"
          class="absolute top-1 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
        >
          {" "}
          Mobile
        </label>
      </div>
    </>
  );
};

export default Detail;
