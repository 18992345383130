import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FailToast from "../Toast/FailToast";
import SuccessToast from "../Toast/SuccessToast";
import axios from "axios";
import { baseUrl } from "../../config";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Loading from "../Loading/Loading";
import OtpModal from "../Modal/OtpModal";

const Account = () => {
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [activeFail, setActiveFail] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [value, setValue] = useState("");
  const [user, setUser] = useState();
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [detectChange, setDetectChange] = useState(false);
  const [onboardingId, setOnboardingId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [passwordFormData, setPasswordFormData] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const userId = localStorage.getItem("userId");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 8;
  };

  // const validatePhone = (phone) => {
  //   const phonePattern = /^\d{10}$/;
  //   return phonePattern.test(phone);
  // };

  const handleChange = (event) => {
    console.log("run");
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setDetectChange(true);
  };
  const handlePasswordChange = (event) => {
    console.log("run password cahnge");
    const { name, value } = event.target;
    setPasswordFormData({ ...passwordFormData, [name]: value });
  };

  // const handleSubmit = () => {

  //   if (formData.name === "") {
  //     setActiveFail(true);
  //     setValue("Oops! Name field cannot be blank");
  //   } else if (!formData.email) {
  //     setActiveFail(true);
  //     setValue("Oops! Email field cannot be blank");
  //   } else if (!validateEmail(formData.email)) {
  //     setActiveFail(true);
  //     setValue("Oops! That looks like an incorrect email");
  //   } else if (!validatePassword(formData.newPassword)) {
  //     setActiveFail(true);
  //     setValue("Oops! Your new password should be at least 8 characters");
  //   } else if (formData.mobile === "") {
  //     setActiveFail(true);
  //     setValue("Oops! Mobile Field cannot be blank");
  //   } else if (!validatePhone(formData.mobile)) {
  //     setActiveFail(true);
  //     setValue("Oops! That doesn’t look like a valid mobile number");
  //   } else {
  //     setActiveSuccess(true);
  //     setValue("Awesome! Account updated successfully");
  //     console.log("formData", formData);
  //   }
  // };

  const handleVerifyEmail = async () => {
    console.log("handle verifyyyyy!!!!!!!!");
    try {
      const response = await axios.post(`${baseUrl}/verify/emailVerifyLink`, {
        email: formData.email,
      });
      console.log("response from verify email", response.data.message);
      alert(response.data.message);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  const handleVerifyPhone = async () => {
    let phone = "+" + formData.mobile;

    try {
      const response = await axios.post(`${baseUrl}/verify/phoneVerifyCode`, {
        phoneNumber: phone,
      });
      console.log("response from verify phone", response.data.message);
      alert(response.data.message);
      setIsModalOpen(true);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  const handleSubmit = () => {
    if (formData.name === "") {
      setActiveFail(true);
      setValue("Oops! Name field cannot be blank");
    } else if (!formData.email) {
      setActiveFail(true);
      setValue("Oops! Email field cannot be blank");
    } else if (!validateEmail(formData.email)) {
      setActiveFail(true);
      setValue("Oops! That looks like an incorrect email");
    } else {
      updateAccount();
    }
  };

  const handlePasswordSubmit = async () => {
    if (!validatePassword(passwordFormData.newPassword)) {
      setActiveFail(true);
      setValue("Oops! Your new password should be at least 8 characters");
    } else {
      const userId = localStorage.getItem("userId");
      try {
        const response = await axios.post(`${baseUrl}/user/changePassword`, {
          formData: passwordFormData,
          userId: userId,
        });
        console.log("response from change Password", response.data);
        setActiveSuccess(true);
        setValue(response.data.message);
      } catch (error) {
        setActiveFail(true);
        setValue(error.response.data.message);
        console.log("Error in fetching data", error.response);
      }
    }
  };

  // const updateAccount = async () => {
  //   const userId = localStorage.getItem("userId");

  //   try {
  //     const response = await axios.put(`${baseUrl}/user/update-account`, {
  //       userId: userId,
  //       onBoardingId: onboardingId,
  //       formData: formData,
  //     });
  //     console.log("updated account details", response.data);
  //     setActiveSuccess(true);
  //     setValue("Awesome! Account updated successfully");

  //     // alert(response.data.msg);
  //   } catch (error) {
  //     // alert(error.response.data.msg);
  //   }
  // };

  const updateAccount = async () => {
    const userId = localStorage.getItem("userId");

    try {
      const response = await axios.put(`${baseUrl}/user/update-account`, {
        userId: userId,
        onBoardingId: onboardingId,
        formData: formData,
      });
      console.log("updated account details", response.data);
      const newToken = response.data.token;
      // if (newToken) {
      //   localStorage.setItem("token", newToken);
      // }
      if (newToken) {
        localStorage.setItem("Token", response.data.token);
        const decoded = jwtDecode(newToken);
        const { username, userId } = decoded;
        localStorage.setItem("username", username);
        localStorage.setItem("userId", userId);
      }

      setActiveSuccess(true);
      setValue("Awesome! Account updated successfully");

      // alert(response.data.msg);
    } catch (error) {
      // alert(error.response.data.msg);
    }
  };

  const fetchUserById = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${baseUrl}/onboard/${userId}`);
      console.log("response from Onboard data", response.data.onboardData);
      setOnboardingId(response.data.onboardData[0]._id);
      setEmailVerified(response?.data.onboardData[0]?.user?.isEmailVerified);
      setPhoneVerified(response?.data.onboardData[0]?.user?.isPhoneVerified);
      setFormData({
        name: response?.data?.onboardData[0]?.user?.username || "",
        email: response?.data?.onboardData[0]?.user?.email || "",
        mobile: response?.data?.onboardData[0]?.mobile || "",
      });
      setIsLoading(false);
    } catch (error) {
      console.log("Error in fetching data", error.response);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserById();
    }
  }, [userId]);

  // removing pop up in 3 second automatically

  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 2000);
    }
    if (activeSuccess) {
      setTimeout(() => {
        setActiveSuccess(false);
        navigate("/dashboard");
      }, 2000);
    }
  }, [activeFail, activeSuccess]);
  return (
    <div className="pb-0">
      {activeFail && (
        <FailToast
          errorValue={value}
          activeFail={activeFail}
          setActiveFail={setActiveFail}
        />
      )}
      {activeSuccess && (
        <SuccessToast
          successValue={value}
          activeSuccess={activeSuccess}
          setActiveSuccess={setActiveSuccess}
        />
      )}
      <div
        class="flex  flex-col space-y-3  py-2 px-3  "
        style={{ width: "450px", height: "512px", borderRadius: "10rem" }}
      >
        <OtpModal
          show={isModalOpen}
          phoneNumber={formData.mobile}
          onClose={() => setIsModalOpen(false)}
        />

        <div class=" mb-1 space-y-1">
          <h1 class="text-color-gray-light font-extrabold text-lg">
            Account Manangement
          </h1>
          <p class="text-color-gray-light font-normal	text-sm	">
            Manage your account details from this section{" "}
          </p>
        </div>

        <div className="bg-[#F1F1F1] p-3 rounded-lg space-y-3 mt-5 ">
          <div class="relative mt-1 w-full">
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              class="border-1 peer block w-full appearance-none rounded-lg border border-[#A1A1A1]  bg-white bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-[#A1A1A1] focus:outline-none focus:ring-0"
              placeholder=" "
            />
            <label
              for="Name"
              class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
            >
              {" "}
              Name{" "}
            </label>
          </div>
          {/* <div className="">
            <div class="relative mt-1 w-full">
              <input
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                class="border-1 peer block w-full appearance-none rounded-lg bg-white border border-[#A1A1A1] bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-[#A1A1A1] focus:outline-none focus:ring-0"
                placeholder=" "
              />
              <label
                for="email"
                class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
              >
                {" "}
                Email{" "}
              </label>
              {emailVerified && (
                <span className="text-green-400">Verified</span>
              )}
            </div>
          </div> */}

          <div className="">
            <div className="relative mt-1 w-full">
              <input
                type="text"
                id="email"
                name="email"
                disabled={true}
                value={formData.email}
                onChange={handleChange}
                className="peer block w-full appearance-none rounded-lg bg-white border border-[#A1A1A1] px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-[#A1A1A1] focus:outline-none focus:ring-0"
                placeholder=""
              />
              <label
                htmlFor="email"
                className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
              >
                Email
              </label>

              {isLoading ? (
                <div className="absolute right-3 top-3">
                  {" "}
                  <Loading />
                </div>
              ) : emailVerified ? (
                <span className="absolute right-3 top-3 text-green-500">
                  Verified
                </span>
              ) : (
                <button
                  onClick={handleVerifyEmail}
                  className="absolute right-3 top-[50%] transform -translate-y-[50%] bg-green-500 text-white rounded px-2 py-1 text-sm"
                >
                  Verify
                </button>
              )}
            </div>
          </div>

          <div class="relative mt-1 w-full">
            <input
              type="text"
              id="mobile"
              name="mobile"
              disabled={true}
              value={formData.mobile}
              onChange={handleChange}
              class="border-1 peer block w-full appearance-none rounded-lg border border-[#A1A1A1] bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-[#A1A1A1] bg-white focus:outline-none focus:ring-0"
              placeholder=" "
            />
            <label
              for="Mobile"
              class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
            >
              {" "}
              Mobile{" "}
            </label>
            {isLoading ? (
              <div className="absolute right-3 top-3">
                <Loading />
              </div>
            ) : phoneVerified ? (
              <span className="absolute right-3 top-3 text-green-500">
                Verified
              </span>
            ) : (
              <button
                onClick={handleVerifyPhone}
                className="absolute right-3 top-[50%] transform -translate-y-[50%] bg-green-500 text-white rounded px-2 py-1 text-sm"
              >
                Verify
              </button>
            )}
          </div>
          <div class="flex gap-3">
            <button
              class="py-2 px-2 rounded-lg text-sm font-medium bg-button-bg hover:bg-button-hover text-white"
              onClick={handleSubmit}
              disabled={!detectChange}
            >
              Save
            </button>
          </div>
        </div>

        <div className="bg-[#F1F1F1] p-3 rounded-lg space-y-3  ">
          <div class="relative mt-1 w-full">
            <input
              type="password"
              id="currentpassword"
              name="oldPassword"
              onChange={handlePasswordChange}
              class="border-1 peer block w-full appearance-none bg-white rounded-lg border border-[#A1A1A1] bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-[#A1A1A1] focus:outline-none focus:ring-0"
              placeholder=" "
            />
            <label
              for="Password"
              class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
            >
              {" "}
              Current Password{" "}
            </label>
          </div>
          <div class="relative mt-1 w-full">
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              onChange={handlePasswordChange}
              class="border-1 peer block w-full bg-white appearance-none rounded-lg border border-[#A1A1A1] bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-[#A1A1A1] focus:outline-none focus:ring-0"
              placeholder=" "
            />
            <label
              for="email"
              class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
            >
              {" "}
              New Password{" "}
            </label>
          </div>

          <div class="flex gap-3">
            <button
              class="py-2 px-2 rounded-lg text-sm font-medium bg-button-bg hover:bg-button-hover text-white"
              onClick={handlePasswordSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
