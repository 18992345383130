
import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar at the top */}
      <Navbar />

      {/* Content area with sidebar and main content */}
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar with fixed width */}
        <Sidebar className="w-64" />

        {/* Main content with flexible width, avoid overflow */}
        <main className="flex-1 p-4 bg-[#f1f1f1] overflow-y-auto">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
