import React, { useEffect, useState } from "react";
import Role from "./Role";
import { Link } from "react-router-dom";
import axios from "axios";
import Skill from "./Skill";
import Detail from "./Detail";
import { useNavigate } from "react-router-dom";
import FailToast from "../Toast/FailToast";
// import SuccessToast from "../Toast/SuccessToast";
import { baseUrl } from "../../config";
// import testtube from "../Images/TestTube.png";
import testtube from "../Images/Testtube logo.png";

const Form = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [skill, setSkill] = useState(0);
  const [role, setRole] = useState(0);
  const [activeFail, setActiveFail] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [value, setValue] = useState("");

  const [roleToggleStates, setRoleToggleStates] = useState({
    CXO: false,
    Product: false,
    Quality: false,
    Engineering: false,
    Others_role: false,
  });
  const [skillToggleStates, setSkillToggleStates] = useState({
    "Identify bugs": false,
    "QA Automation": false,
    "Sanity Testing": false,
    Design: false,
    Others_skill: false,
  });

  const [formData, setFormData] = useState({
    mobile: "",
    role: [],
    skill: [],
  });

  const FormTitles = ["Role", "Skill", "Detail"];

  // const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  // useEffect(() => {
  //   // Listener for messages from the extension
  //   const messageListener = (event) => {
  //     if (event.source !== window) return;
  //     if (event.data.type === "EXTENSION_INSTALLED") {
  //       console.log("Extension is installed:", event.data.data);
  //       setIsExtensionInstalled(true);
  //     }
  //   };

  //   // Add event listener for messages
  //   window.addEventListener("message", messageListener);

  //   // Send a message to check if the extension is installed
  //   window.postMessage({ type: "CHECK_EXTENSION" }, "*");

  //   // Clean up event listener on component unmount
  //   return () => {
  //     window.removeEventListener("message", messageListener);
  //   };
  // }, []);

  // const handleFilterTrueValues = () => {
  // const filteredSkills = Object.entries(skillToggleStates)
  //   .filter(([key, value]) => value === true)
  //   .map(([key]) => key); // Extracting just the keys

  // setSkillCount(filteredSkills.length);
  // console.log("filterSkills", filteredSkills.length);

  // const filteredRole = Object.entries(roleToggleStates)
  //   .filter(([key, value]) => value === true)
  //   .map(([key]) => key); // Extracting just the keys
  // setRoleCount(filteredRole.length());
  // console.log("filterRole", filteredRole.length);
  // return { filteredRole, filteredSkills };
  // };

  const handleFilterTrueValues = () => {
    // const filteredRoles = Object.values(roleToggleStates).filter(
    //   (value) => value
    // ).length;
    // const filteredSkills = Object.values(skillToggleStates).filter(
    //   (value) => value
    // ).length;

    const filteredSkills = Object.entries(skillToggleStates)
      .filter(([key, value]) => value === true)
      .map(([key]) => key); // Extracting just the keys

    setSkill(filteredSkills);
    // console.log("filterSkills", filteredSkills.length);

    const filteredRole = Object.entries(roleToggleStates)
      .filter(([key, value]) => value === true)
      .map(([key]) => key); // Extracting just the keys
    setRole(filteredRole);
    // console.log("filterRole", filteredRole.length);
    // return { filteredRole, filteredSkills };

    // setRoleCount(filteredRoles);
    // setSkillCount(filteredSkills);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // console.log("role", role);
  //   // console.log("skill", skill);
  //   // console.log("formData", formData);

  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("mai run ho rha hu ");
    const userId = localStorage.getItem("userId");

    if (userId) {
      try {
        const payload = {
          mobile: formData.mobile,
          skill: skill,
          role: role,
          user: userId,
        };
        const response = await axios.post(`${baseUrl}/onboard/`, payload);

        // console.log("response", response.data);
        // alert(response.data.msg);
        // alert("onboarding completed !!");
        setActiveSuccess(true);
        setValue("onboarding completed !!");
      } catch (error) {
        // alert(error.response.data.msg);
        setActiveFail(true);
        setValue(error.response.data.msg);
      }
    }
  };

  useEffect(() => {
    handleFilterTrueValues();
  }, [roleToggleStates, skillToggleStates]);

  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 3000);
    }
    if (activeSuccess) {
      setTimeout(() => {
        setActiveSuccess(false);
        navigate("/dashboard");
      }, 2000);
    }
  }, [activeFail, activeSuccess]);

  const validatePage = () => {
    if (page === 0) {
      return role.length > 1;
    } else if (page === 1) {
      return skill.length > 1;
    } else {
      return formData.mobile !== "";
    }
  };

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <Role
          roleToggleStates={roleToggleStates}
          setRoleToggleStates={setRoleToggleStates}
        />
      );
    } else if (page === 1) {
      return (
        <Skill
          skillToggleStates={skillToggleStates}
          setSkillToggleStates={setSkillToggleStates}
        />
      );
    } else {
      return <Detail formData={formData} setFormData={setFormData} />;
    }
  };

  return (
    <>
      {activeFail && (
        <FailToast
          errorValue={value}
          activeFail={activeFail}
          setActiveFail={setActiveFail}
        />
      )}

      {/* <div class="flex items-center justify-center min-h-screen px-6 lg:px-8">
        <div class="max-w-2xl w-full h-[600px] shadow-lg rounded-2xl ring-1 ring-gray-200 flex flex-col">
          <div class="p-8 sm:p-10 flex-grow">
            <div class="relative h-2 rounded-full overflow-hidden bg-[#E3E3E3] ">
              <div
                class="absolute top-0 bottom-0 left-0 rounded-full bg-[#2AB930]  "
                style={{
                  width: page === 0 ? "33.3%" : page == 1 ? "66.6%" : "100%",
                }}
              ></div>
            </div>

            <div className="mt-4">
              <img src={testtube} alt="testtube logo" className="h-12" />
            </div>

            <p class="mt-4  leading-7 text-color-gray-light font-semibold text-xl ">
              Let’s get started
            </p>

            <p class="text-sm font-normal text-color-gray-light">
              {page === 0
                ? "Select 2 roles that describe you the best?"
                : page === 1
                ? "What can we help you with? Select any two"
                : "Please provide your phone number for receiving test reports."}
            </p>
            <div className="">{PageDisplay()}</div>

            <div class="flex gap-4 justify-end p-8 mt-auto">
              {page == 0 ? (
                ""
              ) : (
                <button>
                  <a
                    class="px-3 py-2 cursor-pointer min-w-[80px] text-center text-black-600   rounded-lg   focus:outline-none focus:ring"
                    onClick={() => {
                      setPage((currPage) => currPage - 1);
                    }}
                  >
                    Back
                  </a>
                </button>
              )}
              {page === FormTitles.length - 1 ? (
                <button
                  className={`px-3 py-2 min-w-[80px] text-center border rounded-lg focus:outline-none focus:ring ${
                    validatePage()
                      ? "text-white bg-btn-gradient-auth active:text-white"
                      : "text-color-gray-light-400 bg-gray-300 cursor-not-allowed"
                  }`}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  disabled={!validatePage()}
                >
                  Done
                </button>
              ) : (
                <button
                  className={`px-3 py-2 min-w-[80px] text-center border rounded-lg focus:outline-none focus:ring ${
                    validatePage()
                      ? "text-white bg-btn-gradient-auth active:text-white"
                      : "text-color-gray-light-400 bg-gray-300 cursor-not-allowed"
                  }`}
                  onClick={() => {
                    setPage((currPage) => currPage + 1);
                    handleFilterTrueValues();
                  }}
                  disabled={!validatePage()}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </div> */}
      <div class="flex items-center justify-center min-h-screen ">
        <div class=" w-full max-w-4xl  h-[600px] shadow-lg rounded-2xl ring-1 ring-gray-200 flex flex-col">
          <div class="p-8 sm:p-10 flex-grow">
            <div class="relative h-2 rounded-full overflow-hidden bg-[#E3E3E3]">
              <div
                class="absolute top-0 bottom-0 left-0 rounded-full bg-[#2AB930]"
                style={{
                  width: page === 0 ? "33.3%" : page == 1 ? "66.6%" : "100%",
                }}
              ></div>
            </div>

            <div class="mt-4">
              <img src={testtube} alt="testtube logo" class="h-12" />
            </div>

            <p class="mt-4 leading-7 text-color-gray-light font-semibold text-xl">
              Let’s get started
            </p>

            <p class="text-sm font-normal text-color-gray-light">
              {page === 0
                ? "Select 2 roles that describe you the best?"
                : page === 1
                ? "What can we help you with? Select any two"
                : "Please provide your phone number for receiving test reports."}
            </p>

            <div>{PageDisplay()}</div>
          </div>

          <div class="flex gap-4 justify-end p-8 mt-auto">
            {page == 0 ? (
              ""
            ) : (
              <button>
                <a
                  class="px-3 py-2 cursor-pointer min-w-[80px] text-center text-black-600 rounded-lg focus:outline-none focus:ring"
                  onClick={() => {
                    setPage((currPage) => currPage - 1);
                  }}
                >
                  Back
                </a>
              </button>
            )}

            {page === FormTitles.length - 1 ? (
              <button
                className={`px-3 py-2 min-w-[80px] text-center border rounded-lg focus:outline-none focus:ring ${
                  validatePage()
                    ? "text-white bg-btn-gradient-auth active:text-white"
                    : "text-color-gray-light-400 bg-gray-300 cursor-not-allowed"
                }`}
                onClick={(e) => {
                  handleSubmit(e);
                }}
                disabled={!validatePage()}
              >
                Done
              </button>
            ) : (
              <button
                className={`px-3 py-2 min-w-[80px] text-center border rounded-lg focus:outline-none focus:ring ${
                  validatePage()
                    ? "text-white bg-btn-gradient-auth active:text-white"
                    : "text-color-gray-light-400 bg-gray-300 cursor-not-allowed"
                }`}
                onClick={() => {
                  setPage((currPage) => currPage + 1);
                  handleFilterTrueValues();
                }}
                disabled={!validatePage()}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
