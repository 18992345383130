import React, { useEffect, useState } from "react";
import FloatingInput from "../Input/FloatingInput";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SuccessToast from "../Toast/SuccessToast";
import { baseUrl, configuration } from "../../config";
import Intercom from "@intercom/messenger-js-sdk";
import "./card.css";
const Footprint_Schedule_Card = ({
  footPrint,
  websiteId,
  formData,
  setFormData,
}) => {
  const navigate = useNavigate();
  const [activeFail, setActiveFail] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [value, setValue] = useState("");
  const [selection, setSelection] = useState("frequency");

  window.intercomSettings = {
    app_id: "x5w0eskb",
    custom_launcher_selector: "#help",
  };

  const today = new Date().toISOString().split("T")[0];

  const [form, setForm] = useState({
    interval: "",
    noOfTimes: "",
    footprintId: "",
    endDate: "",
  });

  useEffect(() => {
    setForm({
      interval: "",
      noOfTimes: "",
      footprintId: footPrint._id,
      endDate: "",
    });
  }, [footPrint]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSelectionChange = (event) => {
    const newSelection = event.target.value;
    setSelection(newSelection);
  };

  const handleSchedule = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("Token");
    
    console.log("Token", token);

    if (form.footprintId && form.interval && (form.noOfTimes || form.endDate)) {
      const payload = {
        scheduleForm: form,
        footprintForm: formData,
      };
      if (token) {
        try {

          const response = await axios.post(`${baseUrl}/schedule`, payload, {
            headers: { authorization: `Bearer ${token}` },
          });

          console.log("response", response.data);
          // toast.success(response.data.message);
          setActiveSuccess(true);
          setValue(response.data.message);
        } catch (error) {
          // toast.warning(error.response.data.msg);
          console.log("error", error);
        }
      } else {
        navigate("/signIn");
      }
    } else {
      alert("please enter all fields");
    }

    console.log("formData", formData);
  };

  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 3000);
    }
    if (activeSuccess) {
      setTimeout(() => {
        setActiveSuccess(false);
        const data = { websiteId: websiteId };
        navigate("/footprint-status-list", { state: data });
      }, 2000);
    }
  }, [activeFail, activeSuccess]);

  return (
    <>
      {activeSuccess && (
        <SuccessToast
          successValue={value}
          activeSuccess={activeSuccess}
          setActiveSuccess={setActiveSuccess}
        />
      )}
      <div className="container ">
        <div className="wrapper  w-[50vw] h-[80vh] border border-[#E0E0E0] rounded-xl bg-white">
          <div className="top-section flex justify-between items-center mx-4 my-4">
            <div className="content text-color-gray-light font-semibold text-xl ">
              <h3 className="text-lg">New Test</h3>
            </div>
          </div>
          <FloatingInput
            footPrint={footPrint}
            isReadOnly={true}
            isEditable={true}
            formData={formData}
            setFormData={setFormData}
          />
          <div className="count-info">
            <div className="inner-count-info flex gap-2 text-xs  mx-4">
              <div className="content text-gray-100  mt-1">
                <button
                  //  className="button bg-white border rounded-md text-black px-1 mt-2"
                  className=" button bg-white border border-input-border rounded-[4px] text-color-gray-light text-[10px] px-1.5 py-0.5  tracking-widest font-normal"
                >
                  {footPrint?.capturedSelectors?.length} steps
                </button>
              </div>
            </div>
          </div>

          <div className="mid-section rounded-xl mx-2">
            <div className="selection-box ml-3 mt-4">
              <div className="relative w-full text-xs">
                <div className="flex gap-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="option"
                      value="frequency"
                      checked={selection === "frequency"}
                      onChange={handleSelectionChange}
                      className="custom-radio mr-2"
                    />
                    Frequency
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="option"
                      value="day"
                      checked={selection === "day"}
                      onChange={handleSelectionChange}
                      className="custom-radio mr-2"
                    />
                    Date
                  </label>
                </div>
              </div>
            </div>

            <div className="p-1">
              <div className="flex items-center justify-between">
                <div className="flex items-center ml-2 my-4">
                  <div className="text-color-gray-light font-semibold text-md">
                    Schedule your Test
                  </div>
                </div>
              </div>

              <div className="flex space-x-3 mx-2">
                {/* Select the interval dropdown */}
                <div className="relative w-full text-sm h-12">
                  <select
                    id="floatingSelect2"
                    className="block w-full px-3.5 py-2 rounded-lg border border-[#A1A1A1] appearance-none focus:outline-none peer h-full"
                    name="interval"
                    onChange={handleChange}
                  >
                    <option>Select the interval</option>
                    <option value="30 minutes">Every 30 minutes</option>
                    <option value="60 minutes">Every 60 minutes</option>
                    <option value="240 minutes">Every 4 hours</option>
                    <option value="480 minutes">Every 8 hours</option>
                    <option value="720 minutes">Every 12 hours</option>
                  </select>
                  <label
                    htmlFor="floatingSelect2"
                    className="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5"
                  >
                    Run
                  </label>
                  <span className="absolute inset-y-0 right-2 flex items-center pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </div>

                {/* Select the frequency or date */}
                {selection === "frequency" ? (
                  <div className="relative w-full text-sm h-12">
                    <select
                      id="floatingSelect1"
                      className="block w-full px-3.5 py-2 rounded-lg border border-[#A1A1A1] appearance-none focus:outline-none peer h-full"
                      name="noOfTimes"
                      onChange={handleChange}
                    >
                      <option>Select the frequency</option>
                      <option value="5">5 times</option>
                      <option value="10">10 times</option>
                      <option value="15">15 times</option>
                      <option value="20">20 times</option>
                      <option value="25">25 times</option>
                    </select>
                    <label
                      htmlFor="floatingSelect1"
                      className="absolute text-color-gray-light duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5"
                    >
                      Frequency
                    </label>
                    <span className="absolute inset-y-0 right-2 flex items-center pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                ) : (
                  <div className="relative w-full text-sm h-12">
                    <input
                      id="floatingDate"
                      type="date"
                      className="block w-full px-3.5 py-2 rounded-lg border border-[#A1A1A1] text-md focus:outline-none peer h-full"
                      name="endDate"
                      min={today}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="floatingDate"
                      className="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5"
                    >
                      Select Date
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="p-1 mb-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="ml-4 flex items-center gap-3 my-2 ">
                  <button
                    id="button_schedule_now"
                    className="button bg-button-bg text-white text-xs rounded-lg p-2 flex items-center space-x-2 mt-2"
                    onClick={handleSchedule}
                  >
                    <span>Schedule Now</span>
                    <svg
                      className=""
                      width="12"
                      height="12"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 5H10V10H5V12H12V5Z" fill="white" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                  <a id="help" className="mt-[4px]">
                    <span
                      className="text-xs  text-black cursor-pointer"
                      onClick={() => Intercom("showNewMessage")}
                    >
                      Connect with us for Help
                    </span>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footprint_Schedule_Card;

{
  /* <div className="mid-section rounded-xl  mx-2 ">
<div className="selection-box ml-3 mt-4">


  <div className="relative w-full text-xs">
    <div className="flex gap-4">
      <label className="flex items-center">
        <input
          type="radio"
          name="option"
          value="frequency"
          checked={selection === "frequency"}
          onChange={handleSelectionChange}
          className="custom-radio mr-2 "
        />
        Frequency
      </label>
      <label className="flex items-center">
        <input
          type="radio"
          name="option"
          value="day"
          checked={selection === "day"}
          onChange={handleSelectionChange}
          className="custom-radio mr-2"
        />
        Date
      </label>
    </div>
  </div>






</div>

<div className="p-1">
  <div className="flex items-center justify-between ">
    <div className="flex items-center ml-2 my-4">
      <div className="text-color-gray-light font-semibold text-md">
        Schedule your Test
      </div>
    </div>
  </div>
  <div className="flex space-x-3 mx-2">
    <div className="relative w-full text-sm">
      <select
        id="floatingSelect2"
        className="block w-full px-3.5 appearance-none pr-8 py-2 cursor-pointer rounded-lg border border-[#A1A1A1] focus:outline-none  peer"
        name="interval"
        onChange={handleChange}
      >
        <option >Select the interval</option>
        <option value="30 minutes">Every 30 minutes</option>
        <option value="60 minutes">Every 60 minutes</option>
        <option value="240 minutes">Every 4 hours</option>
        <option value="480 minutes">Every 8 hours</option>
        <option value="720 minutes">Every 12 hours</option>
      </select>
      <label
        htmlFor="floatingSelect2"
        className="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5"
      >
        Run
      </label>

      <span className="absolute inset-y-0 right-2 flex items-center pointer-events-none">
        <svg className="w-4 h-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>

    </div>
    {selection === "frequency" ? (
      <div className="relative w-full text-sm">
        <select
          id="floatingSelect1"
          className="block w-full px-3.5 py-2 rounded-lg border border-[#A1A1A1] focus:outline-none cursor-pointer appearance-none peer"
          name="noOfTimes"
          onChange={handleChange}
        >
          <option> Select the frequency</option>
          <option value="5">5 times</option>
          <option value="10">10 times</option>
          <option value="15">15 times</option>
          <option value="20">20 times</option>
          <option value="25">25 times</option>
        </select>
        <label
          htmlFor="floatingSelect1"
          className="absolute text-color-gray-light duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5"
        >
          Frequency
        </label>

        <span className="absolute inset-y-0 right-2 flex items-center pointer-events-none">
          <svg className="w-4 h-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </div>
    ) : (


      <div className="relative w-full text-sm ">
        <input
          id="floatingDate"
          type="date"
          className="block w-full px-3.5 py-2   rounded-lg border border-[#A1A1A1] text-md focus:outline-none peer cursor-pointer "
          name="endDate"
          min={today}
          onChange={handleChange}
        />
        <label
          htmlFor="floatingDate"
          className="absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5"
        >
          Select Date
        </label>
      </div>


    )}
  </div>
</div>
</div> */
}
