import React, { useState, useEffect, useRef } from "react";
import './CustomSelect.css'; // Import custom CSS for checkbox styling

function CustomSelectStatus({ onChange }) {
  const options = [
    { id: "active", label: "Active" },
    { id: "inactive", label: "Paused" },
    { id: "complete", label: "Completed" },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleCheckboxChange = (optionId) => {
    setSelectedOptions((prev) => {
      const newSelection = prev.includes(optionId)
        ? prev.filter((id) => id !== optionId)
        : [...prev, optionId];
      if (onChange) onChange(newSelection); // Notify parent of change
      return newSelection;
    });
  };
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedLabel =
    selectedOptions.length > 0
      ? `${selectedOptions.length} items selected`
      : "";

  return (
    <div className="relative w-full text-xs" ref={dropdownRef}>
      <div
        onClick={handleDropdownToggle}
        className="flex justify-between items-center w-full px-2 py-2 rounded-md border border-[#A1A1A1] focus:outline-none focus:border-blue-500 cursor-pointer"
      >
        <span className="text-black font-semibold">{selectedLabel}</span>
        {/* Dropdown Icon */}
        <svg
          className={`w-3 h-3 transition-transform transform ${
            isDropdownOpen ? "rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </div>
      <label
        htmlFor="floatingStatus"
        className={`absolute text-gray-500 duration-300 mt-[-7px] text-[16px] transform -translate-y-3 scale-75 top-1.5 z-10 origin-[0] bg-white px-2 left-2 pointer-events-none ${
          selectedOptions.length > 0 || isDropdownOpen
            ? "scale-75 -translate-y-2"
            : "scale-100 translate-y-1/2"
        }`}
      >
        Status
      </label>
      {isDropdownOpen && (
        <div className="absolute w-full bg-white border border-gray-300 mt-1 rounded-md z-10 h-[7rem]">
          {options.map((option) => (
            <label
              key={option.id}
              className={`flex items-center  px-4 py-2.5  cursor-pointer hover:bg-gray-100 ${
                selectedOptions.includes(option.id)
                  ? "text-black font-semibold"
                  : "text-black"
              }`}
            >
              <input
                type="checkbox"
                checked={selectedOptions.includes(option.id)}
                onChange={() => handleCheckboxChange(option.id)}
                className="mr-2 custom-checkbox form-checkbox text-[16px] h-4 w-4 text-black font-semibold rounded focus:ring-0 focus:outline-none"
              />
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomSelectStatus;
