import React, { useEffect, useState } from "react";

import FloatingInput from "../Input/FloatingInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../config";
import Intercom from "@intercom/messenger-js-sdk";
import DeleteModal from "../Modal/DeleteModal";

const Footprint_Info_Card = ({
  footprintId,
  websiteId,
  formData,
  setFormData,
}) => {
  console.log("website Id from Info Card", websiteId);
  const navigate = useNavigate();

  window.intercomSettings = {
    app_id: "x5w0eskb",
    custom_launcher_selector: "#help",
  };
  // const { footprintId } = useParams();
  const [isFilled, setIsFilled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [activeFail, setActiveFail] = useState(false);
  // const [activeSuccess, setActiveSuccess] = useState(false);
  // const [value, setValue] = useState("");
  const [footprint, setFootprint] = useState();

  const toggleIcon = () => {
    setIsFilled(!isFilled);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const fetchFootprintById = async () => {
    if (footprintId) {
      try {
        const response = await axios.get(
          `${baseUrl}/footprint/foot/${footprintId}`
        );
        console.log(
          "response from footprint by id from footprintInfo",
          response.data
        );
        setFootprint(response.data);
      } catch (error) {
        console.log("Error in fetching data", error.response);
      }
    }
  };

  const handleDeleteFootprint = async () => {
    setIsModalOpen(true);
    // confirmAlert({
    //   // title: "Confirm to submit",
    //   message: "Are you sure you want to delete this footprint ?",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => deleteFootprint(),
    //     },
    //     {
    //       label: "No",
    //       onClick: () => console.log("No"),
    //     },
    //   ],
    // });
  };
  const deleteFootprint = async () => {
    if (footprintId) {
      try {
        const response = await axios.delete(
          `${baseUrl}/footprint/${footprintId}`
        );
        console.log("response from footprint by id", response.data);
        // navigate(`/${websiteId}/footprint-all-list`, {
        //   // state: { websiteId: websiteId },
        // });
        navigate(`/${websiteId}/footprint-all-list`);
      } catch (error) {
        console.log("Error in fetching data", error.response);
      }
    }
  };

  useEffect(() => {
    fetchFootprintById();
  }, [footprintId]);

  // useEffect(() => {
  //   if (activeSuccess) {
  //     setTimeout(() => {
  //       setActiveSuccess(false);
  //       const data = { websiteId: websiteId };
  //       navigate("/footprint-status-list", { state: data });
  //     }, 2000);
  //   }
  // }, [activeSuccess]);
  return (
    <>
      {/* {activeSuccess && (
        <SuccessToast
          successValue={value}
          activeSuccess={activeSuccess}
          setActiveSuccess={setActiveSuccess}
        />
      )} */}

      <div className="container ">
        <DeleteModal
          show={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onDelete={deleteFootprint}

        // onConfirm={confirmDelete}
        />

        <div className="wrapper  w-[25vw] h-[58vh] border rounded-xl bg-white ">
          <div className="top-section  items-center mx-4 my-2">
            <div className="content flex justify-between items-center text-color-gray-light font-semibold text-xl ">
              <h3 className="text-lg">Test Detail</h3>

              <Link
                to={`/Savefootprint/${footprintId}/website/${websiteId}`}
                state={{ Back: false }}
              >

                {footprint?.status === "inactive" && (<button
                  className="button bg-button-bg text-white text-xs rounded-lg p-2 flex items-center space-x-2 mt-2"
                >
                  <span>Schedule Now</span>
                  <svg
                    className=""
                    width="12"
                    height="12"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 5H10V10H5V12H12V5Z" fill="white" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10Z"
                      fill="white"
                    />
                  </svg>
                </button>)
                }

              </Link>
            </div>
          </div>

          <FloatingInput
            footPrint={footprint}
            isReadOnly={true}
            isEditable={false}
            formData={formData}
            setFormData={setFormData}
          />

          <div className="count-info">
            <div className="inner-count-info flex gap-2 text-xs  mx-4">
              <div className="content text-gray-100  mt-1">
                <button
                  // className="button bg-white border rounded-md text-black px-1 mt-2"
                  className=" button bg-white border border-input-border rounded-[4px] text-color-gray-light text-[10px] px-1.5 py-0.5  tracking-widest font-normal"

                >
                  {footprint?.capturedSelectors?.length} steps
                </button>
              </div>
              {/* <div className="content text-gray-100  mt-1">
                <button className="button bg-white border rounded-md text-black px-1 mt-2">
                  34 seconds
                </button>
              </div> */}
            </div>
          </div>

          <div className="p-1">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="ml-2 flex items-center gap-2 my-2">
                  <button
                    className="button bg-button-bg text-white text-xs rounded-lg p-2 flex items-center space-x-2 mt-2"
                    onClick={handleDeleteFootprint}
                  >
                    <span>Delete</span>
                  </button>
                  {/* <button
                    className="button bg-button-bg text-white text-xs rounded-lg p-2 flex items-center space-x-2 mt-2"
                    onClick={handleReschedule}
                  >
                    <span>Schedule</span>
                  </button> */}

                  <a id="help" className="mt-[4px]">
                    <span
                      className="text-xs  text-black cursor-pointer"
                      onClick={() => Intercom("showNewMessage")}
                    >
                      Connect with us for Help
                    </span>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footprint_Info_Card;
